import React, { useState } from "react";
import { Button, Card, Grid, Icon, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

const getItemsPerRow = () => {
    // Adjust these values based on your desired breakpoints
    if (window.innerWidth <= 768) {
        return 1; // Show 1 item per row on smaller screens
    } else if (window.innerWidth <= 1524) {
        return 2; // Show 2 items per row on medium screens
    } else {
        return 3; // Show 3 items per row on larger screens
    }
};

const itemsPerRow = getItemsPerRow();

function PropertyCards({ count, properties, onPrevPageChange, onNextPageChange, canPaginate }) {
    return (
        <>
            <Card.Group stackable itemsPerRow={itemsPerRow}>
                {properties.map((property, index) => (
                    <Card key={index} style={{backgroundColor: "#fff", padding: 25}} raised>
                        <Link 
                            to={`/dashboard/clients/${property.client_id}/property/view/${property.id}`}
                            style={{textDecoration: 'none', textAlign: 'justify', color: 'inherit'}}
                        >
                            <Card.Content>
                                <Grid>
                                    <Grid.Row centered>
                                    <Grid.Column width={10}>
                                        <Card.Header>
                                        <h3>{property.property_name ?? ""}</h3>
                                        </Card.Header>
                                        <Card.Description>
                                        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                                            ({property.client_name ?? ""})
                                        </div>
                                        </Card.Description>
                                    </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Link>
                    </Card>
                ))}
            </Card.Group>

            <br />
            
            {canPaginate === true && (
                <>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="3">
                                <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                                    Previous Page
                                    <Icon name="left arrow" />
                                </Button>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="7">
                                <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                                    Next Page
                                    <Icon name="right arrow" />
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </>
            )}
        </>
    )
}

export default PropertyCards;