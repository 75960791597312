import React, { useCallback, useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Card, Grid, Input, List } from "semantic-ui-react";
import "../chat.css";
import Avatar from "react-avatar";
import { FaCircle } from "react-icons/fa";
import ChatWidgetMessenger from "../components/ChatWidgetMessenger";
import { messages as initialMessages, formatRelativeTime, extractFileNameAndExtension } from "../utils";
import sendApiRequest from "../api";
import { UserAvatar } from "../components/UserAvatar";
import { Link } from "react-router-dom";

function Support() {
    const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];

    return (
        <AdminDashLayout>
            <Grid>
                <Grid.Column floated="left" width={7}>
                    <h1>GD SUPPORT SYSTEM</h1>
                    <h3 style={{ color: "#044f66", marginTop: 10, fontSize: 20 }}>
                        Welcome to the GD support system.
                    </h3>
                </Grid.Column>
                
            </Grid>
            <br /> <br />
            <h3 className="" style={{textAlign: 'center'}}>Please choose your interaction</h3>
            <br />
            <Card.Group stackable itemsPerRow={2} centered style={{paddingLeft: '50px'}}>
              <center>

    
                        <Grid columns={2} stackable centered>

                            <Grid.Column>
                                <Link to={'/dashboard/support/clients'}>
                               
                                <Card
                                    key="big"
                                    style={{
                                        backgroundColor:
                                            cardColors[
                                            Math.floor(
                                                Math.random() *
                                                cardColors.length
                                            )
                                            ],
                                        padding: 25,
                                        marginBottom: 20,
                                        height: 200,
                                    }}
                                    raised
                                >

                                    <Card.Content>
                                        <Card.Description>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: "0px",
                                                    cursor: "pointer"
                                                }}

                                            >
                                                <h2
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: 50,
                                                    }}
                                                >
                                                    Clients
                                                </h2>
                                            </div>
                                        </Card.Description>
                                    </Card.Content>

                                </Card>
                                </Link>
                            </Grid.Column>

                            <Grid.Column>

                                <Grid.Column>
                                <Link to={'/dashboard/support/tenants'}>
                                    <Card
                                        key="big"
                                        style={{
                                            backgroundColor:
                                                cardColors[
                                                Math.floor(
                                                    Math.random() *
                                                    cardColors.length
                                                )
                                                ],
                                            padding: 25,
                                            marginBottom: 20,
                                            height: 200,
                                        }}
                                        raised
                                    >

                                        <Card.Content>
                                            <Card.Description>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginTop: "0px",
                                                        cursor: "pointer"
                                                    }}

                                                >
                                                    <h2
                                                        style={{
                                                            textAlign: "center",
                                                            paddingTop: 50,
                                                        }}
                                                    >
                                                        Tenants
                                                    </h2>
                                                </div>
                                            </Card.Description>
                                        </Card.Content>

                                    </Card>
                                    </Link>
                                </Grid.Column>

                            </Grid.Column>
                        </Grid>
                        </center>
                    </Card.Group>
        </AdminDashLayout>
    );
}

export default Support;