import React, { useEffect, useState, forwardRef, useCallback, useRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Breadcrumb, Radio, Message, Grid, Card, Checkbox } from "semantic-ui-react";
import { Link, useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import addWeeks from "date-fns/addWeeks";
import sendApiRequest from "../api"; // Import your API request function
import AuthService from "../auth";
import { formatToYYYYMMDD } from "../utils";
import { toast } from "react-toastify";
import { fetchProject, uploadFiles } from "../apiCalls";
import SignatureCanvas from 'react-signature-canvas';
import { Dropdown } from "semantic-ui-react";

import { Dropzone, FileMosaic } from "@files-ui/react";

const ProjectLandlordCert = () => {
    const user = AuthService.getCurrentUser();

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [project, setProject] = useState({})
    const [clientData, setClientData] = useState({})
    const [housingPhotosFiles, setHousingPhotos] = useState([]);


    const [apiErrors, setApiErrors] = useState(null);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");

    const [signDate, setSignDate] = useState(new Date());
    const [staffOptions, setStaffs] = useState([]);

    const [useImagesFromJob, setUseImagesFromJob] = useState(true);
    const useImagesForJobBtn = useImagesFromJob ? 'Disable Images from this job' : 'Enable Images from this job';

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Extract the parameters
    const type = queryParams.get('type');

    const formattedDate = new Date().toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
    }).replace(/\//g, '-');

    const ini = `${user.firstname[0]}${user.lastname[0]}/${id}/${formattedDate}`;

    const clientName = project.clientName

    const [formData, setFormData] = useState({
        jobSerialNo: `${ini}`,
        name: clientName, // Added missing state
        address: '', // Added missing state
        postCode: '', // Added missing state
        telNo: '', // Added missing state
        landlordName: '', // Added missing state
        landlordAddress: '', // Added missing state
        landlordPostCode: '', // Added missing state
        landlordTelNo: '', // Added missing state
        landlordCopy: false, // Added missing state
        companyName: 'GD HEATING & GAS',
        companyAddress: '46 HOLTON ROAD, UNIT 12, HOLTON HEATH TRADING PARK, POOLE, DORSET',
        companyTelNo: '01202 929898',
        companyPostCode: 'BH16 6LT',
        gasOperativePrintName: '',
        gasSafetyCardSerialNo: '624934',
        businessRegistrationNo: '11551487',
        gasOperativeCopy: false, // Added missing state
        tenantCopy: false, // Added missing state
        appliancesTested: 0, // Added missing state
        coDetectorsTested: 0, // Added missing state
        gasInstallationPipeWork: '', // Added missing state
        emergencyControlValve: '', // Added missing state
        protectiveEquipotentialBonding: '', // Added missing state
        gasTightnessTest: '', // Added missing state
        engineerName: '', // Added missing state
        clientName: '', // Added missing state
        engineerSignature: '', // Added missing state
        clientSignature: null, // Added missing state
        signDate: new Date(),
        appliances: [{
            id: Date.now(),
            applianceType: '',
            location: '',
            make: '',
            model: '',
            flueType: '',
            landlordAppliance: '',
            inspected: '',
            combustionAnalyser: '',
            safetyDevice: '',
            ventilation: '',
            visualCondition: '',
            flueCondition: '',
            fluePerformance: '',
            serviced: '',
            safeToUse: ''
        }],
        defects: [{
            id: Date.now(),
            identified: '',
            actionTaken: '',
            noticeIssued: '',
            serialNo: ''
        }],
        detectors: [{
            id: Date.now(),
            location: '',
            isCODetectorInDate: '',
            isAnApprovedCODetectorFitted: '',
            testingOfCODetectorSatisfactory: ''
        }],
        images: housingPhotosFiles,
        useImagesFromJob: useImagesFromJob
    });

    const sigPadClient = useRef({});
    const sigPadEngineer = useRef({});

    const handleAddAppliance = () => {
        setFormData(prevState => ({
            ...prevState,
            appliances: [...prevState.appliances, {
                id: Date.now(),
                applianceType: '',
                location: '',
                make: '',
                model: '',
                flueType: '',
                landlordAppliance: '',
                inspected: '',
                combustionReading: '',
                safetyDevice: '',
                ventilation: '',
                flueCondition: '',
                fluePerformance: '',
                serviced: '',
                safeToUse: ''
            }]
        }));
    };

    const handleAddDefect = () => {
        setFormData(prevState => ({
            ...prevState,
            defects: [...prevState.defects, {
                id: Date.now(),
                identified: '',
                actionTaken: '',
                noticeIssued: '',
                serialNo: ''
            }]
        }));
    };

    const handleAddDetector = () => {
        setFormData(prevState => ({
            ...prevState,
            detectors: [...prevState.detectors, {
                id: Date.now(),
                location: '',
                isCODetectorInDate: '',
                isAnApprovedCODetectorFitted: '',
                testingOfCODetectorSatisfactory: ''
            }]
        }));
    };

    const handleRemoveDetector = (id) => {
        setFormData(prevState => ({ ...prevState, detectors: prevState.detectors.filter(detector => detector.id !== id) }));
    };

    const handleRemoveDefect = (id) => {
        setFormData({ ...formData, defects: formData.defects.filter(defect => defect.id !== id) });
    };

    const handleRemoveAppliance = (id) => {
        setFormData({ ...formData, appliances: formData.appliances.filter(appliance => appliance.id !== id) });
    };

    const removeHousingPhotosFile = (id) => {
        updateHousingPhotosFiles(housingPhotosFiles.filter((x) => x.id !== id));
    };

    const updateHousingPhotosFiles = (file) => {
        setHousingPhotos(file);
    };

    const handleRemoveImage = (index) => {
        setFormData({ ...formData, images: formData.images.filter((_, i) => i !== index) });
    };

    const filterAndUploadFiles = async (files) => {
        const nonEmptyFiles = files.filter(file => file.size > 0);
        if (nonEmptyFiles.length > 0) {
            const uploadData = await uploadFiles(nonEmptyFiles);
            return uploadData.data.map((x) => ({ name: x.file_name || "", link: (x.urls.path || "") }));
        }
        return [];
    };

    const handleSubmit = async function () {

        setLoading(true); // Set loading to true when the save operation starts

        console.log(formData)

        try {
            if (housingPhotosFiles.length > 0) {
                const uploadedImages = await filterAndUploadFiles(housingPhotosFiles);
                formData.images = [...formData.images, ...uploadedImages];
            }
            let data = {
                projectLandlordCert: {
                    ...formData,
                    images: formData.images,
                    useImagesFromJob: useImagesFromJob
                }
            }

            let r;

            if (type === "tenant") {
                r = await sendApiRequest('post', '/tenant-projects/edit/' + id, data);
            } else {
                r = await sendApiRequest('post', '/projects/edit/' + id, data);
            }

            console.log(r)

            setLoading(false)

            toast("Landlord Cert Record Updated.");
            toast("PDF Generated")


        } catch (error) {
            console.error("Error saving record:", error);
            if (error.response) {
                // Check for 400 Bad Request
                if (error.response.status === 400) {
                    // Loop through the errors and display them
                    error.response.data.errors.forEach(err => {
                        toast.error(`${err.msg} (Field: ${err.path})`); // Display error message
                    });
                } else {
                    toast.error("An error occurred while saving the record."); // Handle other errors
                }
            } else {
                toast.error("Failed to create Landlord Cert Record"); // Fallback error message
            }
        } finally {
            setLoading(false); // Reset loading state after operation
        }


        // try {
        //     // Send the formData to the server using your API request function
        //     await sendApiRequest(
        //         "post",
        //         "/workers/holiday_requests/create",
        //         formData,
        //         localStorage.getItem("token")
        //     );

        //     // Handle success response
        //     setApiSuccess(true);
        //     setLoading(false)
        //     setApiSuccessMessage("Success!");
        //     toast("Success!")
        // } catch (error) {
        //     console.log(error)
        //     // Handle error response
        //     setApiErrors(true);
        //     setApiErrorMessage("Failed to submit holiday request. Please try again later.");
        // }

        // // Simulate loading completion after a delay (remove in a real app)
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000);

    };

    const fetchProjectData = async (id) => {
        let res;
        if (type === "tenant") {
            res = await sendApiRequest("get", `/tenant-projects/fetch?id=${id}`, localStorage.getItem("token"));
        } else {
            res = await sendApiRequest("get", `/projects/fetch?id=${id}`, localStorage.getItem("token"));
        }

        return res.data[0];
    };

    const fetchStaffData = async () => {
        const res = await sendApiRequest("get", "/workers", localStorage.getItem("token"));
        return res.data
            .filter((worker) => worker.role === 2 || worker.role === 1)
            .map((worker) => ({
                key: worker.id,
                text: worker.firstname,
                value: worker.firstname,
            }));
    };

    useEffect(() => {
        const initializeData = async () => {
            try {
                const [fetchedProject, staffData] = await Promise.all([
                    fetchProjectData(id),
                    fetchStaffData()
                ]);

                setProject(fetchedProject);
                setClientData(fetchedProject.clientData);
                setStaffs(staffData);

                const projectLandlordCert = fetchedProject.projectLandlordCert?.[0] || {};
                setFormData(prevState => ({
                    ...prevState,
                    jobSerialNo: projectLandlordCert.jobSerialNo || prevState.jobSerialNo,
                    name: projectLandlordCert.name || fetchedProject.clientData?.clientName,
                    address: projectLandlordCert.address || fetchedProject.clientData?.clientAddress,
                    postCode: projectLandlordCert.postCode || prevState.postCode,
                    telNo: projectLandlordCert.telNo || fetchedProject.clientData?.clientPhone,
                    landlordName: projectLandlordCert.landlordName || fetchedProject.clientData?.clientName,
                    landlordAddress: projectLandlordCert.landlordAddress || fetchedProject.clientData?.clientAddress,
                    landlordPostCode: projectLandlordCert.landlordPostCode || prevState.landlordPostCode,
                    landlordTelNo: projectLandlordCert.landlordTelNo || fetchedProject.clientData?.clientPhone,
                    landlordCopy: projectLandlordCert.landlordCopy || prevState.landlordCopy,
                    companyName: projectLandlordCert.companyName || prevState.companyName,
                    companyAddress: projectLandlordCert.companyAddress || prevState.companyAddress,
                    companyTelNo: projectLandlordCert.companyTelNo || prevState.companyTelNo,
                    companyPostCode: projectLandlordCert.companyPostCode || prevState.companyPostCode,
                    gasOperativePrintName: projectLandlordCert.gasOperativePrintName || prevState.gasOperativePrintName,
                    gasSafetyCardSerialNo: projectLandlordCert.gasSafetyCardSerialNo || prevState.gasSafetyCardSerialNo,
                    businessRegistrationNo: projectLandlordCert.businessRegistrationNo || prevState.businessRegistrationNo,
                    gasOperativeCopy: projectLandlordCert.gasOperativeCopy || prevState.gasOperativeCopy,
                    tenantCopy: projectLandlordCert.tenantCopy || prevState.tenantCopy,
                    appliancesTested: projectLandlordCert.appliancesTested || prevState.appliancesTested,
                    coDetectorsTested: projectLandlordCert.coDetectorsTested || prevState.coDetectorsTested,
                    gasInstallationPipeWork: projectLandlordCert.gasInstallationPipeWork || prevState.gasInstallationPipeWork,
                    emergencyControlValve: projectLandlordCert.emergencyControlValve || prevState.emergencyControlValve,
                    protectiveEquipotentialBonding: projectLandlordCert.protectiveEquipotentialBonding || prevState.protectiveEquipotentialBonding,
                    gasTightnessTest: projectLandlordCert.gasTightnessTest || prevState.gasTightnessTest,
                    engineerName: projectLandlordCert.engineerName || prevState.engineerName,
                    clientName: projectLandlordCert.clientName || prevState.clientName,
                    engineerSignature: projectLandlordCert.engineerSignature || prevState.engineerSignature,
                    clientSignature: projectLandlordCert.clientSignature || prevState.clientSignature,
                    signDate: projectLandlordCert.signDate ? new Date(projectLandlordCert.signDate) : prevState.signDate,
                    appliances: projectLandlordCert.appliances || prevState.appliances,
                    defects: projectLandlordCert.defects || prevState.defects,
                    detectors: projectLandlordCert.detectors || prevState.detectors,
                    images: projectLandlordCert.images || prevState.images,
                    useImagesFromJob: projectLandlordCert.useImagesFromJob || prevState.useImagesFromJob
                }));

                if (projectLandlordCert.engineerSignature) {
                    const canvas = sigPadEngineer.current.getCanvas();
                    const ctx = canvas.getContext('2d');
                    const image = new Image();
                    image.src = projectLandlordCert.engineerSignature;
                    image.onload = () => {
                        ctx.drawImage(image, 0, 0);
                    };
                }

                if (projectLandlordCert.clientSignature) {
                    const canvas = sigPadClient.current.getCanvas();
                    const ctx = canvas.getContext('2d');
                    const image = new Image();
                    image.src = projectLandlordCert.clientSignature;
                    image.onload = () => {
                        ctx.drawImage(image, 0, 0);
                    };
                }

            } catch (error) {
                console.error("Error fetching project data:", error);
            }
        };

        initializeData();
    }, [id]);

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    {/* <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Link to={"/dashboard/projects"}>
                            <Breadcrumb.Section link>Projects</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Breadcrumb.Section><Link to={'/dashboard/projects/view/' + project.id}>{project.projectName}</Link></Breadcrumb.Section>
                    </Breadcrumb> */}
                    {
                        type === "tenant" ? (
                            <Breadcrumb>
                                <Link to={"/dashboard"}>
                                    <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right chevron" />
                                <Link to={`/dashboard/clients/property/${project.propertyId}/flat/view/${project.flatId}`}>
                                    <Breadcrumb.Section link>Flat</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right chevron" />
                                <Link to={`/dashboard/clients/property/flat/${project.flatId}/request-visit/` + id}>
                                    <Breadcrumb.Section link>View: {project.projectName}</Breadcrumb.Section>
                                </Link>

                                <Breadcrumb.Divider icon="right arrow" />
                                <Breadcrumb.Section>Log Invoice - {project.projectName}</Breadcrumb.Section>
                            </Breadcrumb>
                        ) : (
                            <Breadcrumb>
                                <Link to={"/dashboard"}>
                                    <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right arrow" />
                                <Link to={"/dashboard/projects"}>
                                    <Breadcrumb.Section link>Projects</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right arrow" />
                                <Breadcrumb.Section><Link to={'/dashboard/projects/view/' + project.id}>{project.projectName}</Link></Breadcrumb.Section>
                            </Breadcrumb>
                        )
                    }
                </div>
                <Form loading={loading}>
                    {apiErrors ? (
                        <Message
                            error
                            header="Whoops!"
                            list={apiErrorMessage}
                        />
                    ) : null}

                    {apiSuccess ? (
                        <Message
                            success
                            header="Success!"
                            content={apiSuccessMessage}
                        />
                    ) : null}
                    <br />
                    <Grid columns={2}>
                        <Grid.Column>
                            <h2 style={{ fontWeight: 'bold' }}>Add Landlord Safety Record - {project.projectName}</h2>
                        </Grid.Column>
                        <Grid.Column textAlign="right" >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '10px' }}>Job Serial No:</label>
                                <input type="text" placeholder="Enter Job Serial No" style={{ width: '80px' }} value={formData.jobSerialNo} onChange={(e) => setFormData({ ...formData, jobSerialNo: e.target.value })} />
                            </div>
                        </Grid.Column>
                    </Grid>

                    <p>This form allows for the recording of results from the required checks as determined by the current Gas Safety (Installation and Use Regulations.
                        The information recorded on this form does not confirm that the installation was installed by a registered installer or that the installation complies with any relevant Building Regulations. Chimney / Flue systems were inspected visually and checked for satisfactory evacuation of products of combustion,
                        a detailed internal inspection of the chimney / flue system has not been carried.</p>
                    <br />
                    <div>

                        <Grid columns={2}>
                            <Grid.Column width={8}>
                                <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                    JOB ADDRESS
                                </Card.Header>
                                <div className="grey">
                                    <Form.Field>
                                        <label>Name</label>
                                        <input placeholder='Name' value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Address</label>
                                        <textarea placeholder='Address' rows={3} value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Post Code</label>
                                        <input placeholder='Name' value={formData.postCode} onChange={(e) => setFormData({ ...formData, postCode: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Tel No</label>
                                        <input placeholder='Tel No' value={formData.telNo} onChange={(e) => setFormData({ ...formData, telNo: e.target.value })} />
                                    </Form.Field>
                                </div>
                                <div className="title">Testing Overview</div>
                                <div className="grey">
                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label className="labeltitle">Number of appliances tested?</label>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <input type="number" placeholder='Number of appliances tested' value={formData.appliancesTested}
                                                    onChange={(e) => {
                                                        const qty = e.target.value;
                                                        const updatedAppliances = Array.from({ length: qty }, (_, index) => []);

                                                        setFormData(prevState => ({
                                                            ...prevState,
                                                            appliancesTested: qty,
                                                            appliances: updatedAppliances
                                                        })); // Set the new state for appliances and appliancesTested
                                                    }
                                                    } />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label className="labeltitle">Number of audible CO Detectors tested?</label>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <input type="number" placeholder='Number of appliances tested' value={formData.coDetectorsTested}
                                                    onChange={(e) => {
                                                        const qty = e.target.value;
                                                        const updatedCoDetectors = Array.from({ length: qty }, (_, index) => []);

                                                        setFormData(prevState => ({
                                                            ...prevState,
                                                            coDetectorsTested: qty,
                                                            detectors: updatedCoDetectors
                                                        })); // Set the new state for appliances and appliancesTested
                                                    }} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </div>


                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                    LANDLORD NAME / ADDRESS <small>(or agent if appropriate)</small>
                                </Card.Header>
                                <div className="grey">
                                    <Form.Field>
                                        <label>Name</label>
                                        <input placeholder='Name' value={formData.landlordName} onChange={(e) => setFormData({ ...formData, landlordName: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Address</label>
                                        <textarea placeholder='Address' rows={3} value={formData.landlordAddress} onChange={(e) => setFormData({ ...formData, landlordAddress: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Post Code</label>
                                        <input placeholder='Name' value={formData.landlordPostCode} onChange={(e) => setFormData({ ...formData, landlordPostCode: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Tel No</label>
                                        <input placeholder='Tel No' value={formData.landlordTelNo} onChange={(e) => setFormData({ ...formData, landlordTelNo: e.target.value })} />
                                    </Form.Field>
                                </div>
                                <div className="title">Whose Copy Is This? (please tick)</div>
                                <div className="grey">
                                    <label style={{ paddingBottom: '-15px' }}>Landlord / Managing Agent / Home Owner Copy &nbsp;</label>
                                    <Checkbox
                                        checked={formData.landlordCopy}
                                        onChange={() => setFormData({ ...formData, landlordCopy: !formData.landlordCopy })}
                                    />
                                    <br /><br />
                                    <label>Gas Operative Copy &nbsp;</label>
                                    <Checkbox
                                        checked={formData.gasOperativeCopy}
                                        onChange={() => setFormData({ ...formData, gasOperativeCopy: !formData.gasOperativeCopy })}
                                    />
                                    <br /> <br />
                                    <label>Tenant Copy &nbsp;</label>
                                    <Checkbox
                                        checked={formData.tenantCopy}
                                        onChange={() => setFormData({ ...formData, tenantCopy: !formData.tenantCopy })}
                                    />
                                    <br />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                    REGISTERED BUSINESS DETAILS
                                </Card.Header>
                                <div className="grey">
                                    <Form.Field>
                                        <label>Company Name</label>
                                        <input placeholder='Company Name' value={formData.companyName} onChange={(e) => setFormData({ ...formData, companyName: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Address</label>
                                        <textarea placeholder='Address' rows={3} value={formData.companyAddress} onChange={(e) => setFormData({ ...formData, companyAddress: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Post Code</label>
                                        <input placeholder='Post Code' value={formData.companyPostCode} onChange={(e) => setFormData({ ...formData, companyPostCode: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Tel No</label>
                                        <input placeholder='Tel No' value={formData.companyTelNo} onChange={(e) => setFormData({ ...formData, companyTelNo: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Business Registration No</label>
                                        <input placeholder='Business Registration No' value={formData.businessRegistrationNo} onChange={(e) => setFormData({ ...formData, businessRegistrationNo: e.target.value })} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Gas Operative (Print Name)</label>
                                        <Dropdown
                                            placeholder="Select Staff"
                                            fluid

                                            search
                                            selection
                                            options={staffOptions}
                                            required={true}
                                            onChange={(e, { value }) => {
                                                setFormData({ ...formData, gasOperativePrintName: value })
                                            }}
                                            value={formData.gasOperativePrintName}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Gas Safety Card Serial No</label>
                                        <input placeholder='Gas Safety Card Serial No' value={formData.gasSafetyCardSerialNo} onChange={(e) => setFormData({ ...formData, gasSafetyCardSerialNo: e.target.value })} />
                                    </Form.Field>
                                </div>
                            </Grid.Column>
                            <div>
                                {formData.appliances.map((appliance, index) => (
                                    <>
                                        <Grid.Column width={16}>
                                            <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                                APPLIANCE DETAILS
                                            </Card.Header>

                                            <div className="grey" style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
                                                <div className="primary-text">Appliance {index + 1}</div>
                                                <br />
                                                <Grid>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Appliance Type</label>
                                                            <input placeholder='' value={formData.appliances[index]?.applianceType} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, applianceType: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Form.Field>
                                                            <label>Location</label>
                                                            <input placeholder='' value={formData.appliances[index]?.location} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, location: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Make</label>
                                                            <input placeholder='' value={formData.appliances[index]?.make} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, make: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Model</label>
                                                            <input placeholder='' value={formData.appliances[index]?.model} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, model: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Form.Field>
                                                            <label>Flue Type (OF, RS, FL)</label>
                                                            <select value={formData.appliances[index]?.flueType} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, flueType: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="OF">OF</option>
                                                                <option value="RS">RS</option>
                                                                <option value="FL">FL</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Landlord's Appliance</label>
                                                            <select value={formData.appliances[index]?.landlordAppliance} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, landlordAppliance: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <br /> <br /><br />
                                            </div>
                                        </Grid.Column>

                                        <Grid.Column width={16}>
                                            <Card.Header style={{ backgroundColor: '#0a88ae', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold', marginTop: '-55px' }}>
                                                INSPECTION DETAILS
                                            </Card.Header>
                                            <div className="grey">
                                                <Grid>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Appliance Inspected</label>
                                                            <select value={formData.appliances[index]?.inspected} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, inspected: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Form.Field>
                                                            <label>Operating pressure in MBAR or heat (input in Kw)</label>
                                                            <input placeholder='' value={formData.appliances[index]?.operatingPressure} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, operatingPressure: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Combustion analyser reading (if applicable)</label>
                                                            <input placeholder='' value={formData.appliances[index]?.combustionAnalyser} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, combustionAnalyser: e.target.value } : appliance) })} />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Safety device(s) correct operation</label>
                                                            <select value={formData.appliances[index]?.safetyDevice} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, safetyDevice: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Form.Field>
                                                            <label>Ventilation provision satisfactory</label>
                                                            <select value={formData.appliances[index]?.ventilation} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, ventilation: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Visual condition of flue / termination
                                                                satisfactory</label>
                                                            <select value={formData.appliances[index]?.visualCondition} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, visualCondition: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Flue performance checks</label>
                                                            <select value={formData.appliances[index]?.fluePerformance} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, fluePerformance: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="PASS">PASS</option>
                                                                <option value="FAIL">FAIL</option>
                                                                <option value="NA">N/A</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Form.Field>
                                                            <label>Appliance serviced</label>
                                                            <select value={formData.appliances[index]?.serviced} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, serviced: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Form.Field>
                                                            <label>Appliance safe to use</label>
                                                            <select value={formData.appliances[index]?.safeToUse} onChange={(e) => setFormData({ ...formData, appliances: formData.appliances.map((appliance, i) => i === index ? { ...appliance, safeToUse: e.target.value } : appliance) })}>
                                                                <option value="">Choose</option>
                                                                <option value="YES">Yes</option>
                                                                <option value="NO">No</option>
                                                            </select>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </div>
                                            <br />
                                            {index > 0 && (
                                                <Button
                                                    color="red"
                                                    onClick={() => handleRemoveAppliance(appliance.id)}
                                                >
                                                    Remove Appliance
                                                </Button>
                                            )}
                                            <br /><br />

                                        </Grid.Column>
                                    </>
                                ))}
                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Add Appliance"
                                    onClick={handleAddAppliance}

                                    icon="plus"
                                    labelPosition="right"
                                />
                            </div>

                        </Grid>

                    </div>
                    <br /> <br /><br />
                    <Grid.Column width={16}>
                        <Card.Header style={{ backgroundColor: 'orange', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '400px 0px 400px 0px', fontWeight: 'bold' }}>
                            THE NEXT GAS SAFETY CHECK IS DUE WITHIN 12 MONTHS
                        </Card.Header>
                    </Grid.Column>

                    <br />

                    <Grid.Column width={16}>


                        <div>
                            {formData.defects.map((defect, index) => (
                                <>
                                    <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                        DEFECTS IDENTIFIED
                                    </Card.Header>
                                    <Grid.Column width={16}>
                                        <div className="grey" style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
                                            <div className="primary-text">Defects {index + 1}</div>
                                            <br />
                                            <Grid>
                                                <Grid.Column width={5}>
                                                    <Form.Field>
                                                        <label>Defects Identified</label>
                                                        <input placeholder='' value={formData.defects[index]?.identified} onChange={(e) => setFormData({ ...formData, defects: formData.defects.map((defect, i) => i === index ? { ...defect, identified: e.target.value } : defect) })} />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Form.Field>
                                                        <label>Action Taken</label>
                                                        <input placeholder='' value={formData.defects[index]?.actionTaken} onChange={(e) => setFormData({ ...formData, defects: formData.defects.map((defect, i) => i === index ? { ...defect, actionTaken: e.target.value } : defect) })} />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </Grid.Column>
                                    <div className="title">WARNING/ADVICE NOTICE ISSUED (Yes/No) - If YES insert serial no. below and attach copy of notice</div>
                                    <Grid.Column width={16}>
                                        <div className="grey" style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
                                            <Grid>
                                                <Grid.Column width={5}>
                                                    <br />
                                                    <Radio
                                                        label="YES"
                                                        name={`noticeIssued-${index}`} // Unique name for each radio group
                                                        value="true"
                                                        checked={formData.defects[index]?.noticeIssued === "YES"} // Use optional 
                                                        onChange={() => {
                                                            const ini = `${user.firstname[0]}${user.lastname[0]}/${project.id}/${index + 1}`;
                                                            setFormData(prevFormData => ({
                                                                ...prevFormData,
                                                                defects: prevFormData.defects.map((defect, i) =>
                                                                    i === index ? { ...defect, noticeIssued: "YES", serialNo: ini } : defect
                                                                ),
                                                            }));
                                                        }}
                                                    />
                                                    <Radio
                                                        label="NO"
                                                        name={`noticeIssued-${index}`} // Unique name for each radio group
                                                        value="false"
                                                        checked={formData.defects[index]?.noticeIssued === "NO"} // Use optional 
                                                        onChange={() => {
                                                            setFormData({
                                                                ...formData,
                                                                defects: formData.defects.map((defect, i) =>
                                                                    i === index ? { ...defect, noticeIssued: "NO" } : defect
                                                                )

                                                            })
                                                        }}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                    <Form.Field>
                                                        <label>Serial No</label>


                                                        <input placeholder='' value={formData.defects[index]?.serialNo}
                                                            onChange={(e) => setFormData({
                                                                ...formData,
                                                                defects: formData.defects.map((defect, i) =>
                                                                    i === index ? { ...defect, serialNo: e.target.value } : defect
                                                                )
                                                            })}
                                                            readOnly={formData.defects[index].noticeIssued !== "true"} />
                                                    </Form.Field>
                                                </Grid.Column>

                                            </Grid>
                                            <br />
                                            {index > 0 && (
                                                <Button
                                                    color="red"
                                                    onClick={() => handleRemoveDefect(defect.id)}
                                                >
                                                    Remove Defect
                                                </Button>
                                            )}
                                            <br />
                                        </div>
                                    </Grid.Column>

                                </>
                            ))}
                            <br />
                            <Button
                                size="midi"
                                color="primary"
                                content="Add Defect"
                                onClick={handleAddDefect}

                                icon="plus"
                                labelPosition="right"
                            />
                        </div>
                    </Grid.Column>
                    <br /> <br /><br /> <Grid.Column width={16}>


                        <div>
                            {formData.detectors.map((defect, index) => (
                                <>
                                    <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                                        AUDIBLE CARBON MONOXIDE (CO) DETECTOR(S)
                                    </Card.Header>
                                    <Grid.Column width={16}>
                                        <div className="grey" style={{ borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>
                                            <div className="primary-text">Number {index + 1}</div>
                                            <br />
                                            <Grid>
                                                <Grid.Column width={5}>
                                                    <Form.Field>
                                                        <label>Location</label>
                                                        <input placeholder='' value={formData.detectors[index].location} onChange={(e) => setFormData({ ...formData, detectors: formData.detectors.map((detector, i) => i === index ? { ...detector, location: e.target.value } : detector) })} />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                    <Form.Field>
                                                        <label>Is CO Detector In Date?</label>
                                                        <select value={formData.detectors[index].isCODetectorInDate} onChange={(e) => setFormData({ ...formData, detectors: formData.detectors.map((detector, i) => i === index ? { ...detector, isCODetectorInDate: e.target.value } : detector) })}>
                                                            <option value="">Choose</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="NA">NA</option>
                                                        </select>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Form.Field>
                                                        <label>Is An Approved CO Detector Fitted?</label>
                                                        <select value={formData.detectors[index].isAnApprovedCODetectorFitted} onChange={(e) => setFormData({ ...formData, detectors: formData.detectors.map((detector, i) => i === index ? { ...detector, isAnApprovedCODetectorFitted: e.target.value } : detector) })}>
                                                            <option value="">Choose</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="NA">NA</option>
                                                        </select>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                    <Form.Field>
                                                        <label>Testing of CO Detector Satisfactory?</label>
                                                        <select value={formData.detectors[index].testingOfCODetectorSatisfactory} onChange={(e) => setFormData({ ...formData, detectors: formData.detectors.map((detector, i) => i === index ? { ...detector, testingOfCODetectorSatisfactory: e.target.value } : detector) })}>
                                                            <option value="">Choose</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="NA">NA</option>
                                                        </select>
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                            <br />
                                            {index > 0 && (
                                                <Button
                                                    color="red"
                                                    onClick={() => handleRemoveDetector(defect.id)}
                                                >
                                                    Remove Defect
                                                </Button>
                                            )}
                                            <br />
                                        </div>
                                    </Grid.Column>
                                </>
                            ))}
                            <br />
                            <Button
                                size="midi"
                                color="primary"
                                content="Add CO Detector"
                                onClick={handleAddDetector}

                                icon="plus"
                                labelPosition="right"
                            />
                        </div>
                    </Grid.Column>
                    <br /><br />
                    <Grid.Column width={16}>

                        <Card.Header style={{ backgroundColor: 'yellow', color: 'black', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                            Other Installation Checks / Tests Carried Out
                        </Card.Header>
                        <div className="grey">
                            <Grid>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <label>Gas Installation Pipe Work Satisfactory (Visual Inspection)?</label>
                                        <select value={formData.gasInstallationPipeWork} onChange={(e) => setFormData({ ...formData, gasInstallationPipeWork: e.target.value })}>
                                            <option value="">Choose</option>
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                            <option value="NA">NA</option>
                                        </select>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <label>Emergency Control Valve (ECV) Accessible?</label>
                                        <select value={formData.emergencyControlValve} onChange={(e) => setFormData({ ...formData, emergencyControlValve: e.target.value })}>
                                            <option value="">Choose</option>
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                            <option value="NA">NA</option>
                                        </select>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Form.Field>
                                        <label>Protective Equipotential Bonding
                                            Satisfactory?</label>
                                        <select value={formData.protectiveEquipotentialBonding} onChange={(e) => setFormData({ ...formData, protectiveEquipotentialBonding: e.target.value })}>
                                            <option value="">Choose</option>
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                            <option value="NA">NA</option>
                                        </select>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <label>Satisfactory Gas Tightness Test?</label>
                                        <select value={formData.gasTightnessTest} onChange={(e) => setFormData({ ...formData, gasTightnessTest: e.target.value })}>
                                            <option value="">Choose</option>
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                        </select>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>

                        </div>
                    </Grid.Column>
                    <br /> <br />
                    <Grid.Column width={16}>

                        <Card.Header style={{ backgroundColor: '#044f66', color: 'white', padding: '10px', textAlign: 'center', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}>
                            NAMES AND SIGNATURES
                        </Card.Header>
                        <div className="grey">
                            <Grid>
                                <Grid.Column width={8}>
                                    <label>This Safety Record has been issued by:</label>
                                    <br />
                                    <SignatureCanvas
                                        ref={sigPadEngineer}
                                        penColor='black'
                                        canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                                        style={{ border: "1.5px solid #000", borderRadius: "5px" }}
                                        onEnd={() => setFormData({ ...formData, engineerSignature: sigPadEngineer.current.toDataURL('image/png') })}
                                        defaultValue={project?.projectLandlordCert?.engineerSignature || ""} // Set the default value here
                                    />
                                    <br />
                                    <Button size="mini" color="red" onClick={() => sigPadEngineer.current.clear()}>
                                        Clear
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Form.Field>
                                        <label>Print Name: (Mr/Mrs/Miss/Ms)</label>
                                        <input placeholder='' value={formData.engineerName} onChange={(e) => setFormData({ ...formData, engineerName: e.target.value })} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <label>Safety Record received by:</label>
                                    <br />
                                    <SignatureCanvas
                                        ref={sigPadClient}
                                        penColor='black'
                                        canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                                        style={{ border: "1.5px solid #000", borderRadius: "5px" }}
                                        onEnd={() => setFormData({ ...formData, clientSignature: sigPadClient.current.toDataURL('image/png') })}
                                        defaultValue={project?.projectLandlordCert?.clientSignature || ""} // Set the default value here
                                    />
                                    <br />
                                    <Button size="mini" color="red" onClick={() => sigPadClient.current.clear()}>
                                        Clear
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Form.Field>
                                        <label>Print Name: (Mr/Mrs/Miss/Ms)</label>
                                        <input placeholder='' value={formData.clientName} onChange={(e) => setFormData({ ...formData, clientName: e.target.value })} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <label>Date</label>
                                        <input
                                            type="date"
                                            value={formatToYYYYMMDD(formData.signDate)}
                                            onChange={(e) => setFormData({ ...formData, signDate: new Date(e.target.value) })}
                                            style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </div>
                        <br />
                        <Grid.Column width={16}>
                            <label style={{ paddingBottom: 20 }}>
                                Upload Photos
                            </label>
                            <Dropzone
                                accept={"image/*"}
                                onChange={updateHousingPhotosFiles}
                                value={housingPhotosFiles}
                            >
                                {housingPhotosFiles.map((file) => (
                                    <FileMosaic
                                        key={file.id}
                                        {...file}
                                        onDelete={removeHousingPhotosFile}
                                        info
                                        preview
                                    />
                                ))}
                            </Dropzone>
                        </Grid.Column>
                        {formData.images.length > 0 && (
                            <div style={{ marginTop: '20px' }}>
                                {formData.images.map((image, index) => (
                                    <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
                                        <img src={image.link} alt={image.name} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <br />
                                        <Button size="midi" color="red" onClick={() => handleRemoveImage(index)}>
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}

                        {useImagesFromJob && (
                            <>
                                <Grid.Column width={16}>
                                    <label style={{ paddingBottom: 20, paddingTop: 20 }}>
                                        <br />
                                        Images from this job
                                    </label>

                                </Grid.Column>
                                {project.projectImages && project.projectImages.length > 0 && (
                                    <div style={{ marginTop: '20px' }}>
                                        {project.projectImages.map((image, index) => (
                                            <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
                                                <img src={image} style={{ maxWidth: '400px', maxHeight: '250px', borderRadius: '10px' }} />
                                                <br />
                                            </div>
                                        ))}
                                    </div>

                                )}
                            </>
                        )}

                        <br />


                        <div>
                            <label style={{ display: 'block', marginTop: '4px' }}>
                                <input
                                    type="checkbox"
                                    name="disabledImages"
                                    checked={!useImagesFromJob}
                                    onChange={(e) => {
                                        setUseImagesFromJob(!e.target.checked);

                                    }}
                                    style={{ height: '32px', width: '30px' }}
                                />
                                <span style={{ marginLeft: '10px' }}>{useImagesForJobBtn}</span>
                            </label>
                        </div>

                    </Grid.Column>


                    <br /> <br /><br />
                    <div style={{ position: 'fixed', right: '20px', bottom: '20px' }}>


                        <Button
                            size="midi"
                            color="primary"
                            style={{ color: "#fff" }}
                            content={loading ? "Saving..." : "Save Record"} // Change button text based on loading state
                            icon="plus"
                            labelPosition="right"
                            onClick={handleSubmit}
                            loading={loading} // Add loading prop to button
                            disabled={loading} // Disable button while loading
                        />
                    </div>
                    <br /> <br /><br />
                    {apiErrors ? (
                        <Message error header="Whoops!" list={[apiErrorMessage]} />
                    ) : null}
                    {apiSuccess ? (
                        <Message success header="Success!" content={apiSuccessMessage} />
                    ) : null}
                    <br /> <br /><br />
                </Form >

            </AdminDashLayout >
        </>
    );
}

export default ProjectLandlordCert;

