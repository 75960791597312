import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Grid } from "semantic-ui-react";

import PropertyCards from "../components/PropertyCards";
import { fetchProperties } from "../apiCalls";
import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";

function Properties() {
    const user = AuthService.getCurrentUser();
    const user_role = user.role;
  
    const [properties, setProperties] = useState([])
    const [propertiesCount, setPropertiesCount] = useState(0)
    const [page, setPage] = useState(1); // Current page number
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(user_role);
        fetchProperties(page, pageSize, localStorage.getItem('token')).then(data => {
    
          setLoading(false)
          if (data) {
            setPropertiesCount(data.totalCount);
            setProperties(data.data);
          }
        })
    }, [page, pageSize]);
    
    const handlePrevPageChange = () => {
        if (page > 1) {
          setPage(page - 1);
        }
    };
    
    const handleNextPageChange = () => {
        const maxPage = Math.ceil(propertiesCount / pageSize);
        if (page < maxPage) {
          setPage(page + 1);
        }
    };
    
    const canPaginate = () => {
        if (propertiesCount > 20) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 30, paddingRight: 20 }}>
                    <div className="projects-container">
                        <Grid>
                            <Grid.Column mobile={16} computer={11} floated="left">
                                <h3>Properties</h3>
                                <h2 className="timesheet-subtitle">
                                    WELCOME TO GD PROPERTY SYSTEM
                                </h2>
                            </Grid.Column>
                        </Grid>
                    </div>

                    <br />

                    <Form loading={loading ? true : false}>
                        <PropertyCards
                            count={propertiesCount}
                            properties={properties}
                            onPrevPageChange={handlePrevPageChange}
                            onNextPageChange={handleNextPageChange}
                            canPaginate={canPaginate}
                        />
                    </Form>
                </div>
            </AdminDashLayout>
        </>
    );
}

export default Properties;