import {
    Breadcrumb,
    Button,
    Form,
    Grid,
    Checkbox,
    Divider,
    Label,
    Dropdown,
} from "semantic-ui-react";
import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link } from "react-router-dom";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { uploadFiles } from "../apiCalls";
import DatePicker from "react-datepicker";

import sendApiRequest, { API_URL } from "../api";
import { toast } from "react-toastify";

function CreatePipekit() {
    // const [collectionDate, setCollectionDate] = useState(new Date());
    const [files, setFiles] = React.useState([]);
    const [pressureCertfiles, setPressureCert] = useState([]);
    const [housingPhotosFiles, setHousingPhotos] = useState([]);
    const [pipekitCodes, setPipekitCodes] = useState([])
    const [staffOptions, setStaffs] = useState([]);
    const [pipekitPhotos, setPipekitPhotos] = useState([[]]); // Initialize with one empty array for the first photo
    const [pipekitCodeId, setPipekitCodeId] = useState(0)

    const index = 0;

    const [file, setFile] = useState([])

    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
            (res) => {
                const staffData = res.data
                    .filter((worker) => worker.role === 2 || worker.role === 1)
                    .map((workers) => ({
                        key: workers.id,
                        text: workers.firstname,
                        value: workers.id,
                    }))

                setStaffs(staffData);
            }
        );

        sendApiRequest("get", "/pipekits/fetch_codes", localStorage.getItem("token")).then(
            (res) => {
                console.log(res)
                const staffData = res.data
                    .map((workers) => ({
                        key: workers.id,
                        text: workers.code,
                        value: workers.code,
                        note: workers.note,
                        id: workers.id,
                        name: workers.description
                    }))

                setPipekitCodes(staffData);
            }
        );
    }, [])

    const updatePressureCertFiles = (incomingFiles) => {
        //do something with the files
        setPressureCert(incomingFiles);
        //even your own upload implementation
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
    };

    const updateHousingPhotosFiles = (file) => {
        setHousingPhotos(file);
    };

    const removePressureCertFile = (id) => {
        updatePressureCertFiles(pressureCertfiles.filter((x) => x.id !== id));
    };

    const removeHousingPhotosFile = (id) => {
        updateHousingPhotosFiles(housingPhotosFiles.filter((x) => x.id !== id));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const [formData, setFormData] = useState({
        advecoNumber: "",
        pipekitCode: "",
        pipekitPosition: "LH",
        pipekitRefDesc: "",
        pipekitQty: "",
        pipekitPhotos: [],
        assignedTo: "",
        dateRequired: new Date(),
        installConfirm: false,
        pipekitNote: "",
    });

    // Function to determine the quantity based on pipekitQty
    const calculateMaterialQuantity = (pipekitQty) => {
        // Ensure pipekitQty is a number
        const qty = Number(pipekitQty);
    
        // Check if qty is a valid number
        if (isNaN(qty) || qty < 1 || qty > 10) {
            console.error("Invalid pipekitQty:", pipekitQty); // Debugging statement
            return 0; // Return 0 for invalid input
        }
    
        if (qty === 1 || qty === 2) return 1;
        if (qty === 3 || qty === 4) return 2;
        if (qty === 5 || qty === 6) return 3;
        if (qty === 7 || qty === 8) return 4;
        if (qty === 9 || qty === 10) return 5;
    
        return 0; // Default case if qty is not in the specified range
    };

    let lh = [
        {
          "CODE": 18800195,
          "DESCRIPTION": "BOSS 41X21X2.5 PG SLOT 3M BS69461988",
          "QTY": 1
        },
        {
          "CODE": 18801463,
          "DESCRIPTION": "BOSS 41X21 BLACK END CAP BEC 21",
          "QTY": 2
        },
        {
          "CODE": 19300033,
          "DESCRIPTION": "22MM BOSS 2PC LINED CLIP 21/26MM M8/M10 RLXL022 BSI",
          "QTY": 2
        },
        {
          "CODE": 19024097,
          "DESCRIPTION": "M10 X 100MM PRE-CUT THREADED ROD 6323010",
          "QTY": 1
        },
        {
          "CODE": 19024042,
          "DESCRIPTION": "M10 X 50MM PRE-CUT THREADED ROD 6323005",
          "QTY": 1
        },
        {
          "CODE": "07622455",
          "DESCRIPTION": "M10 HEX FULL NUT BZP GRADE 8.8",
          "QTY": 4
        },
        {
          "CODE": 18802602,
          "DESCRIPTION": "BOSS M10 SQUARE PLATE 41X41/21 FP31/10",
          "QTY": 2
        },
        {
          "CODE": 18801323,
          "DESCRIPTION": "BOSS M10 PLAIN CHANNEL NUTS TO SUIT 41X41/21 RAIL M10 PZP",
          "QTY": 2
        },
        {
          "CODE": "00913333",
          "DESCRIPTION": "22X15X22MM S26 END REDUCED TEE XPRESS COPPER 38510",
          "QTY": 2
        },
        {
          "CODE": "00911672",
          "DESCRIPTION": "22MM S12S STREET ELBOW XPRESS COPPER 38320",
          "QTY": 3
        },
        {
          "CODE": "00912523",
          "DESCRIPTION": "22MM S21S OBTUSE STREET ELBOW XPRESS COPPER 38405",
          "QTY": 1
        },
        {
          "CODE": "00911318",
          "DESCRIPTION": "22MM S12 ELBOW XPRESS COPPER 38290",
          "QTY": 3
        },
        {
          "CODE": "00337161",
          "DESCRIPTION": "22X0.9X3M CU TUBE (TX) EN1057 R250 FORMERLY BS2871 TX",
          "QTY": 2
        },
        {
          "CODE": 10911650,
          "DESCRIPTION": "22X1 65084 ADAP UNION NUT MAPRESS COPPER",
          "QTY": 2
        }
      ]

      let rh = [
        {
          "CODE": 18800195,
          "DESCRIPTION": "BOSS 41X21X2.5 PG SLOT 3M BS69461988",
          "QTY": 1
        },
        {
          "CODE": 18801463,
          "DESCRIPTION": "BOSS 41X21 BLACK END CAP BEC 21",
          "QTY": 2
        },
        {
          "CODE": 19300033,
          "DESCRIPTION": "22MM BOSS 2PC LINED CLIP 21/26MM M8/M10 RLXL022 BSI",
          "QTY": 2
        },
        {
          "CODE": 19024097,
          "DESCRIPTION": "M10 X 100MM PRE-CUT THREADED ROD 6323010",
          "QTY": 2
        },
        {
          "CODE": "07622455",
          "DESCRIPTION": "M10 HEX FULL NUT BZP GRADE 8.8",
          "QTY": 4
        },
        {
          "CODE": 18802602,
          "DESCRIPTION": "BOSS M10 SQUARE PLATE 41X41/21 FP31/10",
          "QTY": 2
        },
        {
          "CODE": 18801323,
          "DESCRIPTION": "BOSS M10 PLAIN CHANNEL NUTS TO SUIT 41X41/21 RAIL M10 PZP",
          "QTY": 2
        },
        {
          "CODE": "00913333",
          "DESCRIPTION": "22X15X22MM S26 END REDUCED TEE XPRESS COPPER 38510",
          "QTY": 2
        },
        {
          "CODE": "00911672",
          "DESCRIPTION": "22MM S12S STREET ELBOW XPRESS COPPER 38320",
          "QTY": 3
        },
        {
          "CODE": "00911318",
          "DESCRIPTION": "22MM S12 ELBOW XPRESS COPPER 38290",
          "QTY": 3
        },
        {
          "CODE": "00337161",
          "DESCRIPTION": "22X0.9X3M CU TUBE (TX) EN1057 R250 FORMERLY BS2871 TX",
          "QTY": 2
        },
        {
          "CODE": 10911650,
          "DESCRIPTION": "22X1 65084 ADAP UNION NUT MAPRESS COPPER",
          "QTY": 2
        }
      ]

    const validateForm = () => {
        const errors = [];
        if (!formData.advecoNumber) {
            errors.push("Adveco Number is required.");
        }
        if (!formData.pipekitCode) {
            errors.push("Pipekit Code is required.");
        }
        if (!formData.pipekitQty) {
            errors.push("Quantity is required.");
        }
        if(formData.pipekitQty.length > 10) {
            errors.push("Pipekit Quantity must not be more than 10")
        }
        if (!formData.assignedTo || formData.assignedTo.length === 0) {
            errors.push("Assigned To is required.");
        }
      
        return errors;
    };

    const handlesaveReport = async () => {
        setLoading(true); // Set loading to true when the save operation starts
        try {
            // Validate the form
            const validationErrors = validateForm();
            if (validationErrors.length > 0) {
                validationErrors.forEach(err => toast.error(err)); // Display each error message
                return; // Stop the save operation if there are validation errors
            }

            const data = {
                advecoNumber: formData.advecoNumber,
                pipekitCode: formData.pipekitCode,
                pipekitCodeId: pipekitCodeId,
                pipekitPosition: formData.pipekitPosition,
                pipekitRefDesc: formData.pipekitRefDesc,
                pipekitQty: formData.pipekitQty,
                pipekitPhotos: formData.pipekitPhotos,
                assignedTo: formData.assignedTo,
                dateRequired: formData.dateRequired,
                pipekitNote: formData.pipekitNote,
                installConfirm: formData.installConfirm, // Ensure this is included
            };

            const apiData = {
                ...data,
            };

            if (pipekitPhotos.some(photos => photos.length > 0)) {
                const filesToUpload = pipekitPhotos.flat();
                const uploadData = await uploadFiles(filesToUpload);

                const photos = uploadData.data.map((x) => {
                    return {
                        name: x.file_name || "",
                        link: x.urls.path,
                    };
                });
                apiData.pipekitPhotos = photos;
            }

            if (apiData.pipekitPosition === 'LH') {
                const quantity = calculateMaterialQuantity(apiData.pipekitQty);
                
                // Update the first item quantity
                lh[0].QTY = quantity;

                // Update the quantities for all other items
                for (let i = 1; i < lh.length; i++) {
                    lh[i].QTY *= apiData.pipekitQty;
                }

                apiData.materials = JSON.stringify(lh);
            } else if (apiData.pipekitPosition === 'RH') {
                const quantity = calculateMaterialQuantity(apiData.pipekitQty);
                
                // Update the first item quantity
                rh[0].QTY = quantity;

                // Update the quantities for all other items
                for (let i = 1; i < rh.length; i++) {
                    rh[i].QTY *= apiData.pipekitQty;
                }

                apiData.materials = JSON.stringify(rh);
            }

            const r = await sendApiRequest("post", "/pipekits/create", apiData);

            console.log(r);
            toast("Pipekit Record Created");
            scrollToTop();
            
        } catch (error) {
            console.error("Error saving record:", error);
            if (error.response) {
                // Check for 400 Bad Request
                if (error.response.status === 400) {
                    // Loop through the errors and display them
                    error.response.data.errors.forEach(err => {
                        toast.error(`${err.msg} (Field: ${err.path})`); // Display error message
                    });
                } else {
                    toast.error("An error occurred while saving the record."); // Handle other errors
                }
            } else {
                toast.error("Failed to create Pipekit Record"); // Fallback error message
            }
        } finally {
            setLoading(false); // Reset loading state after operation
        }
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <AdminDashLayout>
            <>
                <div style={{ paddingTop: 40, paddingRight: 10 }}>
                    <h3 className={"primary-color"}>
                        ADD A NEW PIPEKIT RECORD
                    </h3>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Link to={"/dashboard/pipekits"}>
                            <Breadcrumb.Section link>PIPE KITS System</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Breadcrumb.Section>Add A New Record</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <div style={{ paddingTop: 30, paddingRight: 20 }}>
                    <Grid>
                        <Grid.Column width={10}>
                            <Form>
                                <Grid>

                                    <Grid.Column width={11}>
                                        <Form.Input
                                            size="medium"
                                            label="Order No"
                                            placeholder="Enter Adveco Order No"
                                            value={formData.advecoNumber}
                                            onChange={(e) =>
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "advecoNumber",
                                                            value: e.target.value,
                                                        },
                                                    },
                                                    "pipekits"
                                                )
                                            }
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <Form.Select
                                            size="medium"
                                            label="Pipekit Code"
                                            placeholder="Select Pipekit Code"
                                            options={pipekitCodes}
                                            value={formData.pipekitCode}
                                            onChange={(e, { value }) => {
                                                const selectedPipekit = pipekitCodes.find(code => code.value === value);
                                                console.log(selectedPipekit)
                                                setPipekitCodeId(selectedPipekit.id)
                                                const description = selectedPipekit ? `${selectedPipekit.name} \n\r(${selectedPipekit.note})` : ""; // Extract the note value
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "pipekitCode",
                                                            value: value,
                                                        },
                                                    },
                                                    "pipekits"
                                                );
                                                setFormData(prevData => ({
                                                    ...prevData,
                                                    pipekitRefDesc: description, // Set the description in the form data
                                                }));
                                            }}
                                        />
                                    </Grid.Column>



                                    <Grid.Column width={10}>
                                        <Form.Field>
                                            <Label>LH or RH?</Label>
                                            <br /><br />
                                            <Checkbox
                                                label="LH"
                                                checked={
                                                    formData.pipekitPosition === "LH"
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        {
                                                            target: {
                                                                name: "pipekitPosition",
                                                                value: "LH",
                                                            },
                                                        },
                                                        "pipekits"
                                                    )
                                                }
                                            />
                                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;
                                            <Checkbox
                                                label="RH"
                                                checked={
                                                    formData.pipekitPosition === "RH"
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        {
                                                            target: {
                                                                name: "pipekitPosition",
                                                                value: "RH",
                                                            },
                                                        },
                                                        "pipekits"
                                                    )
                                                }
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <Form.TextArea
                                            size="medium"
                                            label="Pipekit Description/Reference"
                                            placeholder="Enter Pipekit Description/Reference"
                                            value={formData.pipekitRefDesc}
                                            readOnly
                                            onChange={(e) =>
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "pipekitRefDesc",
                                                            value: e.target.value,
                                                        },
                                                    },
                                                    "pipekits"
                                                )
                                            }
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <Form.Input
                                            size="medium"
                                            label="Pipekit Quantity"
                                            type="number"
                                            placeholder="Enter Pipekit Quantity"
                                            value={formData.pipekitQty}
                                            max="10"
                                            onChange={(e) => {
                                                const qty = e.target.value; // Get the quantity from the input
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "pipekitQty",
                                                            value: qty,
                                                        },
                                                    },
                                                    "pipekits"
                                                );

                                                // Update pipekitPhotos based on the quantity
                                                const updatedPhotos = Array.from({ length: qty }, (_, index) => []);
                                                setPipekitPhotos(updatedPhotos); // Set the new state for pipekitPhotos
                                            }}
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <label style={{ fontWeight: 'bold' }}>Assigned To</label>
                                        <br />
                                        <Dropdown
                                            placeholder="Select Staff(s)"
                                            fluid
                                            multiple
                                            search
                                            selection
                                            options={staffOptions}
                                            required={true}

                                            onChange={(e, {value}) =>
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "assignedTo",
                                                            value: value,
                                                        },
                                                    },
                                                    "pipekits"
                                                )
                                            }
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <label style={{ fontWeight: 'bold' }}>Date Required</label>
                                        <DatePicker
                                            dateFormat={"dd/MM/yyyy"}
                                            selected={formData.dateRequired}
                                            onChange={(e) =>
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "dateRequired",
                                                            value: e,
                                                        },
                                                    },
                                                    "pipekits"
                                                )
                                            }
                                            customInput={<ExampleCustomInput />}
                                        />
                                    </Grid.Column>

                                    {pipekitPhotos.map((photos, index) => ( // Map through pipekitPhotos
                                        <Grid.Column width={11} key={index}>
                                            <label style={{ paddingBottom: 20 }}>
                                                Pipekit {index + 1} Photo
                                            </label>
                                            <Dropzone
                                                onChange={(incomingFiles) => {
                                                    const updatedPhotos = [...pipekitPhotos]; // Use the new state
                                                    updatedPhotos[index] = incomingFiles;
                                                    setPipekitPhotos(updatedPhotos); // Update the new state
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        pipekitPhotos: updatedPhotos,
                                                    }));
                                                }}
                                                value={photos} // Use the current photos for this index
                                                maxFileSize={8 * 1024 * 1024}
                                            >
                                                {photos.map((f) => ( // Use the current photos for this index
                                                    <FileMosaic
                                                        key={f.id}
                                                        {...f}
                                                        onDelete={() => {
                                                            const updatedPhotos = pipekitPhotos.map((photo, i) =>
                                                                i === index ? photo.filter((x) => x.id !== f.id) : photo
                                                            );
                                                            setPipekitPhotos(updatedPhotos); // Update the new state
                                                            setFormData((prevData) => ({
                                                                ...prevData,
                                                                pipekitPhotos: updatedPhotos,
                                                            }));
                                                        }}
                                                        info
                                                        preview={true}
                                                    />
                                                ))}
                                            </Dropzone>
                                            <br />
                                            {index > 0 && (
                                                <Button
                                                    color="red"
                                                    onClick={() => {
                                                        const updatedPhotos = pipekitPhotos.filter(
                                                            (_, i) => i !== index
                                                        );
                                                        setPipekitPhotos(updatedPhotos); // Update the new state
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            pipekitPhotos: updatedPhotos,
                                                        }));
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            )}
                                        </Grid.Column>
                                    ))}

                                    <Grid.Column width={11}>
                                        <Button
                                            color="green"
                                            icon="plus"
                                            content="Add More Photos"
                                            labelPosition="right"
                                            onClick={() => {
                                                setPipekitPhotos((prevPhotos) => [...prevPhotos, []]); // Add a new empty array for the new photo
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    pipekitPhotos: [...prevData.pipekitPhotos, []],
                                                }));
                                            }}
                                        />
                                            
                                    </Grid.Column>

                                    <Grid.Column width={11}>
                                        <Form.TextArea
                                            size="medium"
                                            label="Note to Merchant"
                                            placeholder="Enter Note to Merchant"
                                            value={formData.pipekitNote}
                                            // readOnly
                                            onChange={(e) =>
                                                handleChange(
                                                    {
                                                        target: {
                                                            name: "pipekitNote",
                                                            value: e.target.value,
                                                        },
                                                    },
                                                    "pipekits"
                                                )
                                            }
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={15} style={{backgroundColor: '#ffffbf'}}>
                                        <Form.Field>
                                            <label style={{ color: "#e85d04" }}>
                                                INSTALLATION CONFIRMATION

                                            </label>
                                            <Checkbox
                                                label="I confirm that i have installed the pipekit correctly."
                                                checked={
                                                    formData.installConfirm ===
                                                    true
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        {
                                                            target: {
                                                                name: "installConfirm",
                                                                value:
                                                                    !formData.installConfirm,
                                                            },
                                                        },
                                                        "pipekits"
                                                    )
                                                }
                                            />
                                            <br />
                                            <small> </small>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>

                                <br /> <br />
                                <div style={{ }}>
                                    <Button
                                        size="big"
                                        color="#18823d"
                                        style={{backgroundColor:"#18823d",color: "#fff"}}
                                        content={loading ? "Saving..." : "Save Record"} // Change button text based on loading state
                                        icon="plus"
                                        labelPosition="right"
                                        onClick={handlesaveReport}
                                        loading={loading} // Add loading prop to button
                                        disabled={loading} // Disable button while loading
                                    />
                                </div>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </>
        </AdminDashLayout>
    );
}

export default CreatePipekit;
