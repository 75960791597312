import React, { useState } from "react";
import { Card, Image, Modal, Button, Label } from "semantic-ui-react";
import Lightbox from "../components/Lightbox";
import LightboxInner from "./LightboxInner";

const ProjectImageCard = ({ images, id, type = "project" }) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (!images || images.length === 0) {
    return <Label>No images yet</Label>;
  }

  const initialImages = images.slice(0, 4); // Display the first 4 images initially
  const remainingImages = images.slice(4);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Card.Description>
      <div style={{ paddingTop: 20, paddingBottom: 10 }}>
        <Lightbox type={type} id={id} images={initialImages} />
        {remainingImages.length > 0 && (
          <div style={{ paddingLeft: 20 }}>
            <Button
              style={{ float: "right" }}
              color="primary"
              content="VIEW ALL"
              icon="eye"
              labelPosition="right"
              size="mini"
              floated="right"
              onClick={openModal}
            />
          </div>
        )}
      </div>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        centered={false}
        size="large"
      >
        <Modal.Header>View All Images</Modal.Header>
        <Modal.Content>
          <Image.Group>
            <LightboxInner images={images} />
          </Image.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} color="black">
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Card.Description>
  );
};

export default ProjectImageCard;
