import { useState, useEffect } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { fetchClientEmails, fetchClients } from "../apiCalls";
import ClientsTable from "../components/ClientsTable";
import ClientEmailTable from "../components/ClientEmailTable";
import { Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

function ClientEmails() {
    const [clientCount, setClientCount] = useState(0);
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1); // Current page number
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(true);
    const [selectedClientType, setSelectedClientType] = useState(""); // State for selected client type

    useEffect(() => {
        fetchClientEmails(page, pageSize, localStorage.getItem("token")).then((data) => {
            setLoading(false)
            console.log(data.data.emails)
            if (data) {
                setClients(data.data.emails);
                setClientCount(data.data.emails.length)
            }
        });
    }, [page, pageSize]); // Add 'page' and 'pageSize' as dependencies

    const handlePrevPageChange = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPageChange = () => {
        const maxPage = Math.ceil(clientCount / pageSize);
        if (page < maxPage) {
            setPage(page + 1);
        }
    };

    const handleDeleteClient = (clientId) => {
        // Remove the client from the clients array
        const updatedClients = clients.filter((client) => client.id !== clientId);
        setClients(updatedClients);
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 30, paddingRight: 20 }}>
                    <Grid columns={2}>
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column textAlign="left" width={8}>
                                <h3>
                                    <span style={{ color: '#05587f' }}>Email Management System</span>
                                </h3>
                            </Grid.Column>
                            <Grid.Column textAlign="right" width={8}>
                                <Link to={"/dashboard/client/emails/create"}>
                                    <Button
                                        color="primary"
                                        content="ADD A NEW EMAIL"
                                        icon="plus"
                                        labelPosition="right"
                                    />
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br /><br />

                    <ClientEmailTable
                        count={clientCount}
                        clients={clients} // Pass filtered clients
                        onPrevPageChange={handlePrevPageChange}
                        onNextPageChange={handleNextPageChange}
                        onDeleteClient={handleDeleteClient}
                    />
                </div>
            </AdminDashLayout>
        </>
    )
}

export default ClientEmails;