import React, {useEffect, useState} from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {Button, Form, Grid, Breadcrumb, Message} from "semantic-ui-react";
import {Link, useNavigate, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {emailRegex, phoneRegex} from "../utils";
import sendApiRequest from "../api";
import toast from "react-hot-toast";
import { fetchClientEmail, fetchClientEmails } from "../apiCalls";

const EditClientEmail = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [propertyName, setPropertyName] = useState("");
    const [clientType, setClientType] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const { id } = useParams()

    useEffect(() => {
        setLoading(false)

        fetchClientEmail(id, localStorage.getItem('token')).then(response => {
            console.log(response)

            setEmail(response.data.email);

        })
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            email: email
        };

        let newErrors;
        newErrors = {};

        if (formData.email !== "") {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.email = "Please enter the clients email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // request
        (async () => {
            const postData = await sendApiRequest("post", "/clients/email/edit/" + id, formData, localStorage.getItem('token'));
            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    const errors = postData.errors;
                    const errorMsg = [];

                    if (postData.errors) {
                        errors.forEach((e) => {
                            errorMsg.push(e.msg);
                        });
                    } else {
                        errorMsg.push(postData.error);
                    }

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.message == "success") {
                    setApiSuccess(true);
                    setApiSuccessMessage("Record Modified Successfully");
                    toast("Record Modified Successfully")
              
                }


            } else {

                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                toast("Error occurred while submitting data to the API.")
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);

                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{paddingTop: 40}}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron"/>
                        <Link to={"/dashboard/client/emails"}>
                            <Breadcrumb.Section link>Client Emails</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow"/>
                        <Breadcrumb.Section>Create Email</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <div style={{paddingTop: 30, paddingRight: 20}}>
                    <Grid>
                        <Grid.Column width={8} floated="left">
                            {apiErrors ? (
                                <Message
                                    error
                                    header="Whoops!"
                                    list={apiErrorMessage}
                                />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                            <Form loading={loading ? true : false}>
                                
                                <Form.Input
                                    size="medium"
                                    label="Email"
                                    placeholder="Enter Email"
                                    value={email}
                                    required={true}
                                    error={errors.email ? {content: errors.email} : null}
                                    onChange={(e) => {
                                        errors.email = null;
                                        setEmail(e.target.value);
                                    }}
                                />
                              
                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Edit"
                                    icon="pencil"
                                    labelPosition="right"
                                    onClick={handleSubmit}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </AdminDashLayout>
        </>
    );
};

export default EditClientEmail;
