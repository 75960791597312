import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, TextArea, Form, Grid, Container } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";

import sendApiRequest from "../api";

const TenantConfirm = () => {
    const [tenantReply, setTenantReply] = useState("");

    const [success, setSuccess] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    // Extract the parameters
    const id = queryParams.get('id');
    const tenantName = queryParams.get('tenantName');
    const date = decodeURIComponent(queryParams.get('date'));
    const projectName = decodeURIComponent(queryParams.get('projectName'));

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const apiData = {
            tenantReply: tenantReply,
        }

        if(tenantReply === "") {
            toast("Please fill in your preferred date & time.");
            return;
        }

        setLoading(true);
        const postData = await sendApiRequest(
            "post",
            "/tenant-projects/confirmation/" + id,
            apiData,
            // localStorage.getItem("token")
        );

        if(postData.success && postData.message === "Project booking approved successfully") {
            setLoading(false);
            setSuccess(true);
            toast("Your preferred time & date has been updated.");
        }
    }

    return (
        <>
            <div
                style={{backgroundColor: "#044f66"}}
            >
                <Container 
                    color="blue"
                >
                    {
                        !success ? (
                            <Grid centered style={{ height: '100vh', marginRight: "20px", marginLeft: "20px"}}>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        {/* Your content here */}
                                        <img class="logo" src="https://gdheatingandgas.b-cdn.net/gdheatingpdflogo.png" alt="Logo"></img>
        
                                        <h2>GD CONFIRMATION SYSTEM</h2>
        
                                        <p>Confirm visit for {projectName}</p>
                                        
                                        <p>Hi, {tenantName}</p>
        
                                        <p>Galamast has requested a visit to your flat on {new Date(date).toLocaleDateString("en-GB")}</p>
        
                                        <p>Please fill out the form to let us know of your availability, and when we can access your property.</p>
                                        <p>Thank you!</p>
                                    </Grid.Column>
                                </Grid.Row>
    
                                <Grid.Row style={{width: "100%"}}>
                                    <Form style={{width: "100%"}}>
                                        <Form.Field>
                                            <label style={{color: "white", fontSize: "16px"}} >Enter preferred date & time here</label>
        
                                            <TextArea
                                                placeholder="Let us know the days and time we can gain access."
                                                onChange={(e) => setTenantReply(e.target.value)}
                                                value={tenantReply}
                                                style={{backgroundColor: "transparent", border: "2px solid white", color: "white", fontSize: "16px"}}
                                            />
                                        </Form.Field>
        
                                        <Button
                                            color="brown"
                                            onClick={handleSubmit}
                                            disabled={loading}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Form>
                                </Grid.Row>
                            </Grid>
                        ) : (
                            <Grid centered style={{ height: '100vh', marginRight: "20px", marginLeft: "20px"}}>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        {/* Your content here */}
                                        <img class="logo" src="https://gdheatingandgas.b-cdn.net/gdheatingpdflogo.png" alt="Logo"></img>
        
                                        <h2>GD CONFIRMATION SYSTEM</h2>

                                        <FaCheckCircle style={{width: "50px", height: "50px"}} width={30} height={30} color="#ADF802"/>

                                        <h3 style={{ color: "#ADF802"}}>Your response has been recorded successfully.</h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                    }
                </Container>
            </div>
            
            <ToastContainer />
        </>
    );
};

export default TenantConfirm;