import { Form, Grid, Checkbox, Button, TextArea, Dropdown } from "semantic-ui-react";
import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD } from "../utils"
import SignatureCanvas from 'react-signature-canvas';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateJobReportModal = ({ defaultData, onSubmitFromParent, type = "projects" }) => {
  const [formData, setFormData] = useState({
    applianceType:
      defaultData?.projectJobReports?.applianceDetails?.applianceType || "",
    make: defaultData?.projectJobReports?.applianceDetails?.make || "",
    modelNo: defaultData?.projectJobReports?.applianceDetails?.modelNo || "",
    KW: defaultData?.projectJobReports?.applianceDetails?.KW || "",
    serialNo: defaultData?.projectJobReports?.applianceDetails?.serialNo || "",
    GCNo: defaultData?.projectJobReports?.applianceDetails?.GCNo || "",
    fuel: defaultData?.projectJobReports?.applianceDetails?.fuel || "",
    condition:
      defaultData?.projectJobReports?.applianceDetails?.condition || "",
    partsRequired:
      defaultData?.projectJobReports?.partsRequired.join("\n") || "",
    merchants: defaultData?.projectJobReports?.merchants.join("\n") || "",
    orderDate: moment(defaultData?.projectJobReports?.orderDate, "DD/MM/YYYY").format("YYYY-MM-DD") || new Date(),
    worksCarriedOut: defaultData?.projectJobReports?.worksCarriedOut || "",
    safety: defaultData?.projectJobReports?.safety || "",
    officeQuoteRequired:
      defaultData?.projectJobReports?.office.quoteRequired || true,
    officeOrderNo: defaultData?.projectJobReports?.office.orderNo || "",
    officeInvoiceNo: defaultData?.projectJobReports?.office.invoiceNo || "",
    comments: defaultData?.projectJobReports?.office.comments || "",
    contactName: defaultData?.projectJobReports?.contact.name || "",
    contactEmail: defaultData?.projectJobReports?.contact.email || "",
    contactSiteAddress: defaultData?.projectJobReports?.contact.siteAddress || "",
    contactPostCode: defaultData?.projectJobReports?.contact.postCode || "",
    contactTelNo: defaultData?.projectJobReports?.contact.telNo || "",
    signedEngineer: defaultData?.projectJobReports?.signed.engineer || "",
    signedEngineerPrintName: defaultData?.projectJobReports?.signed.printName || "",
    signedClient: defaultData?.projectJobReports?.signed.client || "",
    signedClientPrintName: defaultData?.projectJobReports?.signed.clientPrintName || "",
    signedDate: moment(defaultData?.projectJobReports?.signed.date, "DD/MM/YYYY").format("YYYY-MM-DD") || "",
    // Add more form fields as needed
  });

  const [staffOptions, setStaffs] = useState([]);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
    //   (res) => {
    //     // Extract the "data" array from the API response
    //     const responseData = res.data;

    //     // Transform the data to the desired format
    //     const transformedData = responseData.map((item) => ({
    //       key: item.id,
    //       text: item.clientName,
    //       value: item.id,
    //     }));

    //     setClients(transformedData);
    //   }
    // );

    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data
          .filter((worker) => worker.role === 2 || worker.role === 1)
          .map((workers) => ({
            key: workers.id,
            text: workers.firstname,
            value: workers.firstname,
          }))

        setStaffs(staffData);

        console.log('staffs', staffData)
      }
    );
  }, [staffOptions]);

  const sigPadEngineer = useRef({});
  const sigPadClient = useRef({});

  console.log(defaultData);

  const id = defaultData.id;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (date, section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: date.toISOString(),
    }));
  };

  const handleSubmitFromParent = async () => {
    console.log(formData);

    setLoading(true)

    let signedClient, signedEngineer;

    // if (sigPadClient.current.isEmpty()) {
    //   toast.error("Client signature is required.");
    //   return;
    // } else {
      
    // }

    signedClient = sigPadClient.current.getTrimmedCanvas().toDataURL('image/png');

    // if (sigPadEngineer.current.isEmpty()) {
    //   toast.error("Engineer signature is required.");
    //   return;
    // } else {
      
    // }

    signedEngineer = sigPadEngineer.current.getTrimmedCanvas().toDataURL('image/png');

    const data = {
      "projectJobReports": {
        "applianceDetails": {
          "applianceType": formData.applianceType,
          "make": formData.make,
          "modelNo": formData.modelNo,
          "KW": formData.KW,
          "serialNo": formData.serialNo,
          "GCNo": formData.GCNo,
          "fuel": formData.fuel,
          "condition": formData.condition
        },
        "partsRequired": formData.partsRequired.split("\n"),
        "merchants": formData.merchants.split("\n"),
        "orderDate": moment(formData.orderDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        "worksCarriedOut": formData.worksCarriedOut,
        "safety": formData.safety,
        "office": {
          "quoteRequired": formData.officeQuoteRequired,
          "orderNo": formData.officeOrderNo,
          "invoiceNo": formData.officeInvoiceNo,
          "comments": formData.comments,
        },
        "contact": {
          "name": formData.contactName,
          "siteAddress": formData.contactSiteAddress,
          "postCode": formData.contactPostCode,
          "telNo": formData.contactTelNo,
          "email": formData.contactEmail
        },
        "signed": {
          "engineer": signedEngineer,
          "printName": formData.signedEngineerPrintName,
          "client": signedClient,
          "clientPrintName": formData.signedClientPrintName,
          "date": moment(formData.signedDate, "YYYY-MM-DD").format("DD/MM/YYYY")
        }
      }
    }

    console.log(data);

    let r;

    if(type === "tenant-projects") {
      r = await sendApiRequest('post', '/tenant-projects/edit/' + id, data);
    } else if(type === "projects") {
      r = await sendApiRequest('post', '/projects/edit/' + id, data);
    }

    console.log(r);

    setLoading(true)

    toast("Project Report Updated")

    onSubmitFromParent()
  };

  // Add a useEffect hook to set the default signature after the component has rendered
  useEffect(() => {
    if (defaultData?.projectJobReports?.signed.engineer) {
      const canvas = sigPadEngineer.current.getCanvas();
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = defaultData.projectJobReports.signed.engineer;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
    }

    if (defaultData?.projectJobReports?.signed.client) {
      const canvas = sigPadClient.current.getCanvas();
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = defaultData.projectJobReports.signed.client;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
    }

  }, [defaultData]);

  return (
    <div style={{ overflow: "scroll", height: "550px" }}>
      <div
        className={"grp-view-page-further-checks-card"}
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Appliance Details
        </span>
        <br />
        <br />
        <Grid>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Appliance Type"
              name="applianceType"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Appliance Type"
              value={formData.applianceType || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "applianceType", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Make"
              name="make"
              placeholder="Make"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              value={formData.make || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "make", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Model No"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              name="modelNo"
              placeholder="Model No"
              value={formData.modelNo || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "modelNo", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="KW"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              name="KW"
              placeholder="KW"
              value={formData.KW || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "KW", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Serial No"
              name="serialNo"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Serial No"
              value={formData.serialNo || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "serialNo", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="GC No"
              name="GCNo"
              placeholder="GC No"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              value={formData.GCNo || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "GCNo", value: e.target.value } },
                  "projectJobReports"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Form.Field>
              <label>Fuel</label>
              <br />
              <Grid>
                <Grid.Column width={5}>
                  <Checkbox
                    label="Gas"
                    name="fuel"
                    checked={formData.fuel === "Gas"}
                    onChange={() =>
                      handleChange(
                        { target: { name: "fuel", value: "Gas" } },
                        "projectJobReports.applianceDetails"
                      )
                    }
                  />
                </Grid.Column>

                <Grid.Column width={5}>
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    label="Oil"
                    name="fuel"
                    checked={formData.fuel === "Oil"}
                    onChange={() =>
                      handleChange(
                        { target: { name: "fuel", value: "Oil" } },
                        "projectJobReports.applianceDetails"
                      )
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                </Grid.Column>

                <Grid.Column width={5}>
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    label="LPG"
                    name="fuel"
                    checked={formData.fuel === "LPG"}
                    onChange={() =>
                      handleChange(
                        { target: { name: "fuel", value: "LPG" } },
                        "projectJobReports.applianceDetails"
                      )
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                </Grid.Column>

                <Grid.Column width={5}>
          
                  <Checkbox
                    label="Air Con"
                    name="fuel"
                    checked={formData.fuel === "Air Con"}
                    onChange={() =>
                      handleChange(
                        { target: { name: "fuel", value: "Air Con" } },
                        "projectJobReports.applianceDetails"
                      )
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                </Grid.Column>

              </Grid>
              <Grid>
                <Grid.Column width={5}>
                  <label>Other</label>
                  <br />
                  <br />
                  <Form.Input
                    size="medium"
                    name="fuel"
                    placeholder="Other"
                    style={{
                      border: "1.5px solid #044f66",
                      borderRadius: "5px",
                      marginTop: "-10px",
                    }}

                    onChange={(e) => handleChange(e, "fuel")}
                  />
                </Grid.Column>
              </Grid>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={15}>
            <Form.Field>
              <label>Condition</label>
              <br />
              <Checkbox
                label="Good"
                name="good"
                checked={formData.condition === "Good"}
                onChange={() =>
                  handleChange(
                    { target: { name: "condition", value: "Good" } },
                    "projectJobReports.applianceDetails"
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <Checkbox
                label="Fair"
                name="fair"
                checked={formData.condition === "Fair"}
                onChange={() =>
                  handleChange(
                    { target: { name: "condition", value: "Fair" } },
                    "projectJobReports.applianceDetails"
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <Checkbox
                label="Poor"
                name="poor"
                checked={formData.condition === "Poor"}
                onChange={() =>
                  handleChange(
                    { target: { name: "condition", value: "Poor" } },
                    "projectJobReports.applianceDetails"
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <Checkbox
                label="Unserviceable"
                name="unserviceable"
                checked={formData.condition === "Unserviceable"}
                onChange={() =>
                  handleChange(
                    {
                      target: { name: "condition", value: "Unserviceable" },
                    },
                    "projectJobReports.applianceDetails"
                  )
                }
              />
              &nbsp;&nbsp;&nbsp; |
            </Form.Field>
          </Grid.Column>
        </Grid>
        <br />
      </div>
      <br /> <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Parts Required
        </span>
        <br />
        <br />
        <TextArea
          size="medium"
          name="partsRequired"
          placeholder="Parts Required"
          style={{
            width: 400,
            height: 150,
            border: "1.5px solid #044f66",
            borderRadius: "5px",
            padding: "10px",
          }}
          value={formData.partsRequired}
          onChange={(e) =>
            handleChange(
              { target: { name: "partsRequired", value: e.target.value } },
              "projectJobReports"
            )
          }
        />
        <br />
      </div>
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Merchants
        </span>
        <br />
        <br />
        <TextArea
          size="medium"
          name="merchants"
          placeholder="Merchants"
          style={{
            width: 400,
            height: 150,
            border: "1.5px solid #044f66",
            borderRadius: "5px",
            padding: "10px",
          }}
          value={formData.merchants}
          onChange={(e) =>
            handleChange(
              { target: { name: "merchants", value: e.target.value } },
              "projectJobReports"
            )
          }
        />
        <br />
      </div>
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Order Date
        </span>
        <br />
        <br />
        <Form.Input
          type="date"
          value={formData.orderDate}
          className=""
          style={{ width: "400px" }}
          onChange={(e) =>
            handleChange(
              { target: { name: "orderDate", value: e.target.value } },
              "projectJobReports"
            )
          }
        />
        <br />
      </div>
      <br /> <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Works Carried Out / Comments
        </span>
        <br />
        <br />
        {/* <TextArea
          size="medium"
          name="worksCarriedOut"
          style={{
            width: 400,
            height: 150,
            border: "1.5px solid #044f66",
            borderRadius: "5px",
            padding: "10px",
          }}
          placeholder="Works Carried Out / Comments"
          value={formData.worksCarriedOut}
          onChange={(e) =>
            handleChange(
              { target: { name: "worksCarriedOut", value: e.target.value } },
              "projectJobReports"
            )
          }
        /> */}
                        <ReactQuill 
                          style={{
                            width: 400,
                            height: 150,
                            // border: "1.5px solid #044f66",
                            borderRadius: "5px",
                            padding: "10px",
                            color: "black",
                          }}
                          theme="snow" 
                          value={formData.worksCarriedOut} 
                          // onChange={setDescription} 
                          onChange={(content) =>
                            handleChange(
                              { target: { name: "worksCarriedOut", value: content } },
                              "projectJobReports"
                            )
                          }
                        />
        <br />
      </div>
      <br /> <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Safety
        </span>
        <br />
        <br />
        <Grid.Column width={15}>
          <Form.Field>
            <Grid>
              <Grid.Column width={6}>
                <Checkbox
                  label="Gas Safety Certification Issued"
                  name="gasSafetyCertification"
                  checked={
                    formData.safety === "Gas Safety Certification Issued"
                  }
                  onChange={() =>
                    handleChange(
                      {
                        target: {
                          name: "safety",
                          value: "Gas Safety Certification Issued",
                        },
                      },
                      "projectJobReports.safety"
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Checkbox
                  label="Warning Notice Issued"
                  name="warningNoticeIssued"
                  checked={formData.safety === "Warning Notice Issued"}
                  onChange={() =>
                    handleChange(
                      {
                        target: {
                          name: "safety",
                          value: "Warning Notice Issued",
                        },
                      },
                      "projectJobReports.safety"
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Input
                  size="medium"
                  name="safety"
                  placeholder="Other"
                  style={{
                    border: "1.5px solid #044f66",
                    borderRadius: "5px",
                    marginTop: "-10px",
                  }}
                  onChange={(e) =>
                    handleChange(
                      {
                        target: { name: "safety", value: e.target.value },
                      },
                      "projectJobReports.safety"
                    )
                  }
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        </Grid.Column>
        <br />
      </div>
      <br />
      <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Office
        </span>
        <br />
        <br />
        <Grid.Column width={15}>
          <Grid>
            <Grid.Column width={5}>
              <Form.Checkbox
                size="medium"
                label="Quote Required"
                name="officeQuoteRequired"
                style={{ marginTop: "30px" }}
                checked={formData.officeQuoteRequired === true}
                onChange={() =>
                  handleChange(
                    {
                      target: { name: "officeQuoteRequired", value: !formData.officeQuoteRequired },
                    },
                    "projectJobReports.office"
                  )
                }
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Input
                size="medium"
                label="Order No"
                style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
                name="orderNo"
                placeholder="Order No"
                value={formData.officeOrderNo}
                onChange={(e) =>
                  handleChange(
                    {
                      target: { name: "officeOrderNo", value: e.target.value },
                    },
                    "projectJobReports.office"
                  )
                }
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Input
                size="medium"
                label="Invoice No"
                name="invoiceNo"
                style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
                placeholder="Invoice No"
                value={formData.officeInvoiceNo}
                onChange={(e) =>
                  handleChange(
                    {
                      target: { name: "officeInvoiceNo", value: e.target.value },
                    },
                    "projectJobReports.office"
                  )
                }
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <TextArea
                size="medium"
                name="comments"
                placeholder="Comments"
                style={{
                  width: 400,
                  height: 150,
                  border: "1.5px solid #044f66",
                  borderRadius: "5px",
                  padding: "10px",
                }}
                value={formData.comments}
                onChange={(e) =>
                  handleChange(
                    { target: { name: "comments", value: e.target.value } },
                    "projectJobReports.office"
                  )
                }
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <br />
        <br />
      </div>
      <br />
      <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Contact
        </span>
        <br />
        <br />
        <Grid>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Name"
              name="name"
              placeholder="Name"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              value={formData.contactName}
              onChange={(e) =>
                handleChange(
                  { target: { name: "contactName", value: e.target.value } },
                  "projectJobReports.contact"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Site Address"
              name="siteAddress"
              placeholder="Site Address"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              value={formData.contactSiteAddress}
              onChange={(e) =>
                handleChange(
                  {
                    target: {
                      name: "contactSiteAddress",
                      value: e.target.value,
                    },
                  },
                  "projectJobReports.contact"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Post Code"
              name="postCode"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Post Code"
              value={formData.contactPostCode}
              onChange={(e) =>
                handleChange(
                  {
                    target: { name: "contactPostCode", value: e.target.value },
                  },
                  "projectJobReports.contact"
                )
              }
            />
          </Grid.Column>
        </Grid>
        <br />
        <Grid>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Tel No"
              name="telNo"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Tel No"
              value={formData.contactTelNo}
              onChange={(e) =>
                handleChange(
                  { target: { name: "contactTelNo", value: e.target.value } },
                  "projectJobReports.contact"
                )
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Email"
              name="email"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Email"
              value={formData.contactEmail}
              onChange={(e) =>
                handleChange(
                  { target: { name: "contactEmail", value: e.target.value } },
                  "projectJobReports.contact"
                )
              }
            />
          </Grid.Column>
        </Grid>

        <br />
      </div>
      <br />
      <br />
      <div className={"grp-view-page-further-checks-card"}>
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Signed
        </span>
        <br />
        <br />
        <Grid>
          <Grid.Column width={9}>
            <label style={{ color: "#044f66", fontSize: "20px" }}>Engineer Signature</label>
            <SignatureCanvas
              ref={sigPadEngineer}
              penColor='black'
              canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              clearOnResize={false} // Add this line to prevent clearing on resize
            />
            <br />
            <Button size="mini" color="red" onClick={() => sigPadEngineer.current.clear()}>
              Clear
            </Button>
          </Grid.Column>
          <Grid.Column width={5}>
            <label style={{ color: "#044f66", fontSize: "20px" }}>Engineer Name</label>
            <Dropdown
              placeholder="Select Engineer"
              fluid
              search
              selection
              options={staffOptions}
              
              required={true}
              value={formData.signedEngineerPrintName}
              onChange={(e, { value }) => {
                handleChange(
                  { target: { name: "signedEngineerPrintName", value: value } },
                  "projectJobReports.signed"
                )
              }}
            />

          </Grid.Column>
          <Grid.Column width={9}>
            <label style={{ color: "#044f66", fontSize: "20px" }}>Client Signature</label>
            <SignatureCanvas
              ref={sigPadClient}
              penColor='black'
              canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              defaultValue={defaultData?.projectJobReports?.signed.client || ""} // Set the default value here

            />
            <br />
            <Button size="mini" color="red" onClick={() => sigPadClient.current.clear()}>
              Clear
            </Button>
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Client Name"
              name="printName"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Client Name"
              value={formData.signedClientPrintName}
              onChange={(e) =>
                handleChange(
                  { target: { name: "signedClientPrintName", value: e.target.value } },
                  "projectJobReports.signed"
                )
              }
            />
          </Grid.Column>

        </Grid>
        <Grid>

          <Grid.Column width={5}>
            <label>Date</label>

            <Form.Input
              type="date"
              value={formData.signedDate}
              className=""
              style={{ width: "400px" }}
              onChange={(e) =>
                handleChange(
                  { target: { name: "signedDate", value: e.target.value } },
                  "projectJobReports.signed"
                )
              }
            />
          </Grid.Column>
        </Grid>
      </div>
      <br />
      {loading && (
        <>
          <Button color="primary">
            Loading...
          </Button>
        </>
      )}

      {!loading && (
        <>
           <Button color="primary" onClick={handleSubmitFromParent}>
              Save Changes
            </Button>
        </>
      )}

     
    </div>
  );
};

export default UpdateJobReportModal;
