import React, { useState, useEffect } from "react";
import { Table, Button, Grid } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";

import AdminDashLayout from "../components/AdminDashLayout";
import "../App.css";
import { fetchFlatsByProperty } from "../apiCalls";

const ViewProperty = () => {
    const { propertyId, clientId } = useParams();
    const [clientFlats, setClientFlats] = useState([]);
    
    useEffect(() => {
        fetchFlatsByProperty(propertyId, localStorage.getItem('token')).then(response => {
            console.log(response)
            const data = response.data;
            setClientFlats(data);
        });
    }, []);

    const getFlatNumber = (flatName) => {
        const match = flatName.match(/\d+$/);
        return match ? parseInt(match[0]) : 0;
    };

    const sortFlats = (flats) => {
        return flats.sort((a, b) => {
            const numA = getFlatNumber(a.flat_name);
            const numB = getFlatNumber(b.flat_name);
            return numA - numB;
        });
    }

    const renderFlatDetails = () => {
        return sortFlats(clientFlats).map((flat, index) => (
            <Table.Row key={index}>
                <Table.Cell>
                    <Link to={`/dashboard/clients/property/${flat.property_id}/flat/view/${flat.id}`}>
                        <Button
                            color="blue"
                        >
                            {flat.flat_name}
                        </Button>
                    </Link>
                </Table.Cell>
                <Table.Cell>{flat.tenant_name}</Table.Cell>
                <Table.Cell>{flat.tenant_address}</Table.Cell>
                <Table.Cell>{`${flat.tenant_email} / ${flat.tenant_phone_number}`}</Table.Cell>
                <Table.Cell>{flat.lastVisit ? new Date(flat.lastVisit).toLocaleDateString("en-GB") : ""}</Table.Cell>
            </Table.Row>
        ));
    };

    return (
        <AdminDashLayout>
            <div className="timesheet-container">
              <Grid style={{ marginTop: "40px" }} verticalAlign='midddle'>
                <Grid.Column mobile={16} computer={7} floated="left">
                  <h1>
                    <span className="timesheet-subtitle">TENANT LOG SYSTEM</span>
                  </h1>
                  <p>Welcome to the Tenant Log & Maintenance system.</p>
                </Grid.Column>

                <Grid.Column mobile={16} floated="right" computer={3}>
                    <div style={{ float: "right" }}>
                        <Link to={`/dashboard/clients/${clientId}/property/${propertyId}/flat/create`}>
                            <Button
                                color="primary"
                                content="CREATE FLAT"
                                icon="plus"
                                labelPosition="right"
                            />
                        </Link>
                    </div>
                </Grid.Column>
              </Grid>
            </div>

            <br />

            {/* Property flats table. */}
            <Table celled className="custom-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>FLAT</Table.HeaderCell>
                        <Table.HeaderCell>TENANT NAME</Table.HeaderCell>
                        <Table.HeaderCell>TENANT ADDRESS</Table.HeaderCell>
                        <Table.HeaderCell>TENANT CONTACT</Table.HeaderCell>
                        <Table.HeaderCell>LAST VISIT</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderFlatDetails()}
                </Table.Body>
            </Table>
        </AdminDashLayout>
    );
};

export default ViewProperty;