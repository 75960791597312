import React, { useState } from "react";
import { Icon, Input, Table, Button, Grid, Modal, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { convertTime } from "./ConvertTime";
import { toast } from "react-toastify";
import { deleteClient, deleteClientEmail } from "../apiCalls";

function ClientEmailTable({ count, clients, onPrevPageChange, onNextPageChange, onDeleteClient }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [openModals, setOpenModals] = useState({}); // Separate state for modals

    const handleDeleteModalOpen = (clientId) => {
        setOpenModals((prevOpenModals) => ({
            ...prevOpenModals,
            [clientId]: true,
        }));
    };

    const handleDeleteModalClose = (clientId) => {
        setOpenModals((prevOpenModals) => ({
            ...prevOpenModals,
            [clientId]: false,
        }));
    };

    const filteredClients = clients && clients.filter((client) =>
        [
            client.email,
        ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    return (
        <>
            <Grid columns={2} verticalAlign="middle">
                <Grid.Column textAlign="left" width={8}>
                    <span style={{ fontSize: 17, paddingRight: 15 }}>{count} emails currently being CC'ed</span>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    <Input
                        icon="search"
                        iconPosition="left"
                        placeholder="Search emails"
                        className=""
                        size="midi"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ marginBottom: 0 }} // Ensure no extra margin
                    />
                </Grid.Column>
            </Grid>

            <Table basic="very" padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S/N</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredClients && filteredClients.map((client, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{client.email}</Table.Cell>
                            <Table.Cell>{convertTime(client.created_at)}</Table.Cell>
                            <Table.Cell>
                                <Link to={`/dashboard/client/emails/edit/${client.id}`}>
                                    <Button compact size="mini" as="a" color="primary">
                                        Edit
                                    </Button>{" "}
                                </Link>
                                /{" "}
                                <Modal
                                    dimmer={"blurring"}
                                    basic={true}
                                    onClose={() => handleDeleteModalClose(client.id)}
                                    onOpen={() => handleDeleteModalOpen(client.id)}
                                    open={openModals[client.id] || false} // Use separate state for each modal
                                    size="small"
                                    trigger={
                                        <Button compact size="mini" as="a" color="red">
                                            Delete
                                        </Button>
                                    }
                                >
                                    <Header icon>
                                        <Icon name='archive' />
                                        Delete Client: {client.clientName}?
                                    </Header>
                                    <Modal.Content>
                                        <p style={{ textAlign: "center" }}>
                                            Are you sure?
                                        </p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button inverted={true} color="red" onClick={() => handleDeleteModalClose(client.id)}>
                                            <Icon name="remove" /> No
                                        </Button>
                                        <Button inverted={true} color="green" onClick={async () => {
                                            handleDeleteModalClose(client.id);
                                            toast('Record Successfully Deleted', {
                                                type: "success"
                                            });

                                            onDeleteClient(client.id);

                                            await deleteClientEmail(client.id, localStorage.getItem('token'));
                                        }}>
                                            <Icon name="checkmark" /> Yes
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">
                            <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                                Previous Page
                                <Icon name="left arrow" />
                            </Button>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="7">
                            <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                                Next Page
                                <Icon name="right arrow" />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
}

export default ClientEmailTable;
