import React, { useState } from "react";
import { Card, Button, Modal, Grid, Form } from "semantic-ui-react";
import { updateImages, uploadFiles, updateTenantImages } from "../apiCalls";
import { Dropzone, FileMosaic } from "@files-ui/react";
import ProjectImageCard from "./ProjectIimageCard";
import { toast } from "react-toastify";
import AuthService from '../auth'

const ProjectImageCardWithModal = ({ id, images, onUploadComplete, type = "projects" }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = AuthService.getCurrentUser();

  const user_role = user.role;

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const updateFiles = (incomingFiles) => {
    // Remove existing files with the same name and size from incomingFiles to avoid duplicates
    const filteredFiles = incomingFiles.filter((incomingFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === incomingFile.name &&
          existingFile.size === incomingFile.size
      );
    });

    // Set the new files (including the filtered incoming files)
    setFiles([...files, ...filteredFiles]);
  };

  const removeFile = (fileId) => {
    // Remove a file from the state
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
  };

  const handleUpload = async () => {

    if (files.length === 0) {
      toast("Kindly choose a file")
      return;
    }

    if (files.length > 0) {
      setLoading(true);
      // Upload files and get the URLs
      const uploadData = await uploadFiles(files);
      const uploadedUrls = uploadData.data.map((fileInfo) => {
        return fileInfo.urls.path;
      });

      // Concatenate the newly uploaded URLs with the existing images
      const updatedImages = images
        ? [...images, ...uploadedUrls]
        : [...uploadedUrls];

      // Notify the parent component that the upload is complete with the updated images
      if(type === "projects") {
        await updateImages(id, updatedImages);
      } else if(type === "tenant-projects") {
        await updateTenantImages(id, updatedImages);
      }
      
      onUploadComplete(updatedImages);

      setFiles([]); // Clear the uploaded files
      closeModal(); // Close the modal
      toast("Upload completed..")
      setLoading(false);
    }
  };

  return (
    <Card.Content>
      <Grid>
        <Grid.Column floated="left" width={9}>
          <Card.Header>
            <h2>Images from this job</h2>
          </Card.Header>
        </Grid.Column>
        <Grid.Column floated="right" width={6}>
          {(user_role == 1 || user_role == 2) && (
            <>
              <Button
                style={{ float: "right" }}
                color="primary"
                content="Upload New Images"
                icon="upload"
                labelPosition="right"
                size="mini"
                onClick={openModal}
              />
            </>
          )}

          <Modal open={modalOpen} onClose={closeModal} size="small">
            <Modal.Header>Upload Images</Modal.Header>
            <Form loading={loading}>
              <Modal.Content>
                <Dropzone
                  onChange={updateFiles}
                  value={files}
                  maxFileSize={8 * 1024 * 1024}
                >
                  {files.map((file) => (
                    <FileMosaic
                      key={file.id}
                      {...file}
                      onDelete={removeFile}
                      info
                      preview={true}
                    />
                  ))}
                </Dropzone>
              </Modal.Content>
            </Form>

            <Modal.Actions>
              {loading === false && (
                <>
                  <Button onClick={handleUpload} color="primary">
                    Upload
                  </Button>
                </>
              )}

              <Button onClick={closeModal} color="black">
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Column>
      </Grid>
      {/* Display the existing images */}
      <ProjectImageCard id={id} images={images} type={type} />
    </Card.Content>
  );
};

export default ProjectImageCardWithModal;
