import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid, Breadcrumb, Message } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FileMosaic, Dropzone } from "@files-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sendApiRequest from "../api";
import { uploadFiles } from "../apiCalls";
import { formatToYYYYMMDD, requiresMultipleVisitOptions } from "../utils";
import AddProjectMilestoneComponent from "../components/AddProjectMilestoneComponent";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateProject = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [files, setFiles] = React.useState([]);
  const [clientsOptions, setClients] = useState([]);
  const [milestones, setMilestones] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      assignedTo: [],
      visitName: "",
    },
  ]);

  const [
    projectRequiresMultipleSiteVisits,
    setprojectRequiresMultipleSiteVisits,
  ] = useState("no");

  const [staffOptions, setStaffs] = useState([]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'], // Text formatting
      [{ 'color': [] }, { 'background': [] }],   // Text color and highlight
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'], // Add links and images
      ['clean'],         // Remove formatting
    ],
  };

  useEffect(() => {
    sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
      (res) => {
        // Extract the "data" array from the API response
        const responseData = res.data;

        // Filter the data based on the client type
        const filteredData = responseData.filter(item => item.client_type === 'big');

        // Transform the filtered data to the desired format
        const transformedData = filteredData.map((item) => ({
          key: item.id,
          text: item.clientName,
          value: item.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)); // Sort alphabetically by clientName

        setClients(transformedData);
      }
    );

    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data
          .filter((worker) => worker.role === 2 || worker.role === 1)
          .map((workers) => ({
            key: workers.id,
            text: workers.firstname,
            value: workers.id,
          }))

        setStaffs(staffData);
      }
    );
  }, []);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState(new Date());

  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiErrors, setApiErrors] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");

  const updateFiles = (incomingFiles) => {
    //do something with the files
    setFiles(incomingFiles);
    //even your own upload implementation
  };

  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleMilestonesChange = (updatedMilestones) => {
    setMilestones(updatedMilestones);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const handleSubmit = () => {
    setLoading(true);
    setApiSuccess(false);
    setApiErrors(false);
    scrollToTop();

    const formData = {
      projectName: name,
      clientId: client,
      clientAddress: clientAddress,
      assignedToStaffsId: assignedTo,
      projectDesc: description,
      projectStartDate: formatToYYYYMMDD(startDate || new Date()),
      projectEndDate: formatToYYYYMMDD(endDate || new Date()),
    };

    // console.log(parentMilestones);

    if (projectRequiresMultipleSiteVisits === "yes") {
      formData.projectMilestones = milestones;
    }

    let newErrors;
    newErrors = {};

    // Check 'name' entry
    if (formData.name !== "") {
      // Name is not empty
    } else {
      newErrors.name = "Please enter the project's name";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'clientId' entry
    if (formData.clientId !== "") {
      // Client ID is not empty
    } else {
      newErrors.clientId = "Please select a client";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'clientAddress' entry
    if (formData.clientAddress !== "") {
      // Client Address is not empty
    } else {
      newErrors.clientAddress = "Please enter the client's address";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'assignedToStaffsId' entry
    if (formData.assignedToStaffsId !== "") {
      // Assigned To Staffs ID is not empty
    } else {
      newErrors.assignedToStaffsId = "Please select assigned staff";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'projectDesc' entry
    if (formData.projectDesc !== "") {
      // Project Description is not empty
    } else {
      newErrors.projectDesc = "Please enter the project description";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'projectStartDate' entry
    if (formData.projectStartDate !== "") {
      // Project Start Date is not empty
    } else {
      newErrors.projectStartDate = "Please enter the project start date";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Check 'projectEndDate' entry
    if (formData.projectEndDate !== "") {
      // Project End Date is not empty
    } else {
      newErrors.projectEndDate = "Please enter the project end date";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Login request
    (async () => {
      try {

        // Construct formData here, including the photoUri if it was set
        const apiData = {
          ...formData,
        };

        if (files.length > 0) {
          // Upload files and set the photoUri
          const uploadData = await uploadFiles(files);
          
          const schematicUris = [];
        
          for (const item of uploadData.data) {
            schematicUris.push(item.urls.path);
          }
        
          apiData.projectSchematics = schematicUris;
        }
        
        // Send the API request
        const postData = await sendApiRequest(
          "post",
          "/projects/create",
          apiData,
          localStorage.getItem("token")
        );

        if (postData !== null) {
          setLoading(false);
          // Handle API response here
          if (postData.errors || postData.error) {
            // Handle errors
            setApiErrors(true);
            setApiSuccess(false);
            const errors = postData.errors || [postData.errors];
            const errorMsg = errors.map((e) => e.msg);
            setApiErrorMessage(errorMsg);
          } else if (postData.message === "success") {
            // Handle success
            setApiSuccess(true);
            setApiSuccessMessage("Project Created Successfully");
            // Reset your form state here if needed
            // setName('')
            // setClient('')
            // setClientAddress('')
            // setAssignedTo('')
            // setDescription('')
            // setStartDate('')
            // setEndDate('')
          }
        } else {
          // Handle API request error
          setApiErrorMessage([
            "Error occurred while submitting data to the API.",
          ]);
          setApiErrors(true);
          setApiSuccess(false);
        }
      } catch (error) {
        // Handle any unexpected errors
        setApiErrorMessage([
          "Error occurred while submitting data to the API.",
        ]);
        setApiErrors(true);
        setApiSuccess(false);
        console.error(error);
      } finally {
        // Ensure setLoading is set to false
        setLoading(false);
      }
    })();

    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  };

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/projects"}>
              <Breadcrumb.Section link>Projects</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Create Project</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column width={8} floated="left">
              {apiErrors ? (
                <Message error header="Whoops!" list={apiErrorMessage} />
              ) : null}

              {apiSuccess ? (
                <Message
                  success
                  header="Success!"
                  content={apiSuccessMessage}
                />
              ) : null}
              <Form loading={loading ? true : false}>
                <Form.Input
                  size="medium"
                  label="Project Name"
                  placeholder="Enter Project Name"
                  value={name}
                  required={true}
                  error={errors.name ? { content: errors.name } : null}
                  onChange={(e) => {
                    errors.name = null;
                    setName(e.target.value);
                  }}
                />
                <label>Client</label>
                <Dropdown
                  placeholder="Select Client"
                  fluid
                  search
                  selection
                  options={clientsOptions}
                  required={true}
                  error={errors.clientId ? { content: errors.clientId } : null}
                  onChange={(e, { value }) => {
                    errors.clientId = null;
                    setClient(value);
                  }}
                />
                <br />
                <Form.Input
                  size="medium"
                  label="Client Address / Job Address"
                  placeholder="Enter Client/Job Address"
                  value={clientAddress}
                  required={true}
                  error={
                    errors.clientAddress
                      ? { content: errors.clientAddress }
                      : null
                  }
                  onChange={(e) => {
                    errors.clientAddress = null;
                    setClientAddress(e.target.value);
                  }}
                />

                <label>Assigned To</label>
                <Dropdown
                  placeholder="Select Staff(s)"
                  fluid
                  multiple
                  search
                  selection
                  options={staffOptions}
                  required={true}
                  error={
                    errors.assignedToStaffsId
                      ? { content: errors.assignedToStaffsId }
                      : null
                  }
                  onChange={(e, { value }) => {
                    errors.assignedToStaffsId = null;
                    setAssignedTo(value);
                  }}
                />
                <br />

                  <label>Project Description / Requirement</label>
                <ReactQuill 
                  style={{ height: 300 }} 
                  theme="snow" 
                  value={description} 
                  onChange={setDescription} 
                  modules={modules}
                />
                <br /> <br /><br />

                {/* <Form.TextArea
                  style={{ minHeight: 150 }}
                  label="Project Description / Requirement"
                  placeholder="Enter Project Description / Requirement(s)"
                  value={description}
                  required={true}
                  error={
                    errors.description ? { content: errors.description } : null
                  }
                  onChange={(e) => {
                    errors.description = null;
                    setDescription(e.target.value);
                  }}
                ></Form.TextArea> */}

                <label style={{ paddingBottom: 20 }}>Project Schematics</label>
                <Dropzone
                  onChange={updateFiles}
                  value={files}
                  maxFileSize={8 * 1024 * 1024}
                >
                  {files.map((file) => (
                    <FileMosaic
                      key={file.id}
                      {...file}
                      onDelete={removeFile}
                      info
                      preview={true}
                    />
                  ))}
                </Dropzone>
                <br />
                <label>Does this project require multiple site visits?</label>
                <Dropdown
                  placeholder="No"
                  fluid
                  selection
                  options={requiresMultipleVisitOptions}
                  defaultValue={"No"}
                  defaultSelectedLabel={"No"}
                  error={
                    errors.requiresMultipleVisit
                      ? { content: errors.requiresMultipleVisit }
                      : null
                  }
                  onChange={(e, { value }) => {
                    errors.requiresMultipleVisit = null;
                    setprojectRequiresMultipleSiteVisits(value);
                  }}
                />
                <br />
                {projectRequiresMultipleSiteVisits === "yes" && (
                  <>
                    <AddProjectMilestoneComponent
                      milestones={milestones}
                      onMilestonesChange={handleMilestonesChange}
                      staffOptions={staffOptions}
                    />
                  </>
                )}

                {projectRequiresMultipleSiteVisits === "no" && (
                  <>
                    <label style={{ paddingBottom: 20 }}>
                      Project Start Date
                    </label>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      customInput={<ExampleCustomInput />}
                    />
                    <br />
                    <br />
                    <label style={{ paddingBottom: 20 }}>
                      Project End Date
                    </label>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      customInput={<ExampleCustomInput />}
                    />
                    <br />
                  </>
                )}
                <br />
                <Button
                  size="midi"
                  color="primary"
                  content="Create Project"
                  icon="plus"
                  labelPosition="right"
                  onClick={handleSubmit}
                />
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </AdminDashLayout>
    </>
  );
};

export default CreateProject;
