import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid } from "semantic-ui-react";
import ProjectCards from "../components/ProjectCards";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import sendApiRequest from "../api";
import { fetchProjects } from "../apiCalls";

function Projects() {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const [projects, setProjects] = useState([])
  const [projectsCount, setProjectsCount] = useState(0)
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(user_role);
    fetchProjects(page, pageSize, localStorage.getItem('token')).then(data => {

      setLoading(false)
      if (data) {
        setProjectsCount(data.totalCount);
        setProjects(data.data);
      }
    })
  }, [page, pageSize]);

  const handlePrevPageChange = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPageChange = () => {
    const maxPage = Math.ceil(projectsCount / pageSize);
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const canPaginate = () => {
    if (projectsCount > 20) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <div className="projects-container">
            <Grid>
              <Grid.Column mobile={16} computer={11} floated="left">
                <h3>Projects</h3>
                <h4 className="projects-subtitle">
                  An overview of all projects -- On-going, completed etc
                </h4>
              </Grid.Column>
              <Grid.Column mobile={16} computer={5}>

                {user_role === 1 && (
                  <>

                    <div className="filter-options">
                      <div className="filter-buttons">
                        <Link to="/dashboard/projects/create">
                          <Button
                            color="primary"
                            content="Create Project"
                            icon="plus"
                            labelPosition="right"
                          />
                        </Link>
                        <Link to="/dashboard/projects/create-small-project">
                          <Button
                            color="red"
                            content="Create Small Project"
                            icon="plus"
                            labelPosition="right"
                          />
                        </Link>
                      </div>
                    </div>


                  </>

                )}
              </Grid.Column>
            </Grid>
          </div>
          <br />
          <Form loading={loading ? true : false}>
            <ProjectCards
              count={projectsCount}
              projects={projects}
              onPrevPageChange={handlePrevPageChange}
              onNextPageChange={handleNextPageChange}
              canPaginate={canPaginate}
              defaultStatus="on-going"
            />
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
}

export default Projects;
